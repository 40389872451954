import React, { useEffect } from 'react';
import {
  Table, Container, Row, Col,
} from 'reactstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CardPanel from '../../../../shared/components/widgets/CardPanel';
import { SiteImage, SiteName } from '../../../../shared/components/domainSupport';
import useSellerData from '../../AccountDashboard/components/useSellerData';
import SalesComboGraph from '../../Dashboard/components/SalesCombo';
import { DateViewOptions } from '../../../../shared/components/filters/DateViewFilter';
import { getPlanStatus } from '../config';

export default function PaidDashboardPage({ linkedSpApi, panelLoad }) {
  const siteName = SiteName();
  const selectedSeller = useSelector(store => store.sellerSelect);
  const [loading, results, error] = useSellerData(selectedSeller, moment().subtract(14, 'day'), moment().subtract(1, 'day'), DateViewOptions.DAY);
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const spApiStatus = linkedSpApi ? (<p><span className="lnr lnr-checkmark-circle" style={{ color: '#91C753', fontSize: '20px', verticalAlign: 'middle' }} /> ACTIVE</p>) : (<p><span className="lnr lnr-warning" style={{ color: '#FF0000', fontSize: '20px', verticalAlign: 'middle' }} /> NEEDS ATTENTION <a href="/customer/onboarding/sp-api/start">Click here to activate</a></p>);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6}>
          <CardPanel
            showButtons={false}
          >
            <Row>
              <div style={{
                display: 'flex', margin: 'auto', maxWidth: '1000px',
              }}
              >
                <SiteImage />
              </div>
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col>
                <span style={{ textAlign: 'center' }}>
                  <h2>Welcome to {siteName}!</h2>
                </span>
              </Col>
            </Row>
            <Row>
              &nbsp;
            </Row>
          </CardPanel>
        </Col>
        <Col md={6}>
          <CardPanel
            showButtons={false}
          >
            <Table responsive striped className="dashboard__table-orders" id="SubsInfo">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Setting</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{selectedSeller.label}</td>
                </tr>
                <tr>
                  <td>Billing Identifier</td>
                  <td>{selectedSeller.externalCustomerId}</td>
                </tr>
                <tr>
                  <td>Marketplace Identifier</td>
                  <td>{selectedSeller.externalAccountId}</td>
                </tr>
                <tr>
                  <td>Marketplace, Currency, Symbol</td>
                  <td>{selectedSeller.mpName}, {selectedSeller.currencyCode}, {selectedSeller.currencySymbol}</td>
                </tr>
                <tr>
                  <td>Amazon Advertising API</td>
                  <td><span className="lnr lnr-checkmark-circle" style={{ color: '#91C753', fontSize: '20px', verticalAlign: 'middle' }} /> ACTIVE</td>
                </tr>
                <tr>
                  <td>Amazon Selling Partner API</td>
                  <td>{panelLoad ? (<p>Loading...</p>) : spApiStatus}</td>
                </tr>
                <tr>
                  <td>Plan</td>
                  <td>{(selectedSeller.plan || 'Trial').toUpperCase()}</td>
                </tr>
                <tr>
                  <td>Plan Status</td>
                  <td>{getPlanStatus(selectedSeller.planStatus).cleanPlanStatus}</td>
                </tr>
              </tbody>
            </Table>
          </CardPanel>
        </Col>
        <Col md={12}>
          <CardPanel
            showButtons={false}
          >
            <SalesComboGraph
              loading={loading}
              data={results && results.data}
            />
          </CardPanel>
        </Col>
      </Row>
    </Container>
  );
}
