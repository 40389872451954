import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment'
import Picker from 'rsuite/DateRangePicker'
import lodash from 'lodash'

export default function DateRangePicker(props) {
  const {
    label, startDate, endDate, onDateChange, loading, allowedRange, dynamicPredefinedRanges,
  } = props;

  const today = moment();
  const oneWeekAgo = moment().subtract(1, 'week');
  const oneMonthAgo = moment().subtract(1, 'month');

  const [pickerId] = useState(lodash.uniqueId('date-range-picker-'));
  const [pickerDates, setPickerDates] = useState(null);
  const [sentDates, setSentDates] = useState(null);
  const [pickerState, setPickerState] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const theme = useSelector(store => store.theme);
  const pickerClasses = classNames(theme.className, 'topbar__dynamicDropdownWidth', 'rs-picker-daterange', 'rs-picker-menu', theme.className.endsWith('light') ? 'rs-theme-light' : 'rs-theme-dark');

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  })

  const predefinedRanges = dynamicPredefinedRanges && dynamicPredefinedRanges.length > 0 ? dynamicPredefinedRanges : [
    {
      label: 'Today',
      value: [today.toDate(), today.toDate()],
      placement: 'left',
    },
    {
      label: 'Last 7 days',
      value: [oneWeekAgo.toDate(), today.toDate()],
      placement: 'left',
    },
    {
      label: 'Last 30 days',
      value: [moment().subtract(30, 'days').toDate(), moment().subtract(1, 'days').toDate()],
      placement: 'left',
    },
    {
      label: 'This week',
      value: [moment().startOf('week').toDate(), today.toDate()],
      placement: 'left',
    },
    {
      label: 'Last week',
      value: [oneWeekAgo.startOf('week').toDate(), oneWeekAgo.endOf('week').toDate()],
      placement: 'left',
    },
    {
      label: 'This month',
      value: [moment().startOf('month').toDate(), today.toDate()],
      placement: 'left',
    },
    {
      label: 'Last month',
      value: [oneMonthAgo.startOf('month').toDate(), oneMonthAgo.endOf('month').toDate()],
      placement: 'left',
    },
  ]

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setPickerState(false);
    }
  }

  const onExiting = () => {
    if (sentDates !== pickerDates) {
      setSentDates(pickerDates)
      onDateChange(pickerDates[0], pickerDates[1]);
    }
  }

  return (
    <>
      {label && (
        <p style={{ whiteSpace: 'nowrap', paddingTop: '10px' }}>{label}</p>
      )}
      <div id={pickerId} className={pickerClasses}>
        <Picker
          defaultValue={[startDate, endDate]}
          ranges={predefinedRanges}
          onChange={setPickerDates}
          onKeyDown={handleKeyDown}
          onExiting={onExiting}
          container={document.getElementById(pickerId)}
          format="yyyy-MM-dd"
          loading={loading}
          onOpen={() => setPickerState(true)}
          onClose={() => setPickerState(false)}
          open={pickerState}
          shouldDisableDate={allowedRange && allowedRange.start && allowedRange.end ? Picker.allowedRange(allowedRange.start, allowedRange.end) : null}
        />
      </div>
    </>
  )
}
