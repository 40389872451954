import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Row,
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import config from '../../../config';
import DateRangePicker from '../widgets/DateRangePicker';
import DatePicker from '../widgets/DatePicker';
import { doFetch } from '../../../helpers';
import Modal from '../Modal';
import { SellerSelect, User, Theme } from '../../prop-types/MainProps';
import { countryOptionsArray, countryOptionDefault } from '../../../containers/Onboarding/amazon/components/OnboardingConfig';

LicenseManager.setLicenseKey(config.agGridKey);

// const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

function getBooleanValue(cssSelector) {
  return document.querySelector(cssSelector).checked === true;
}

class TableBuilder extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    // eslint-disable-next-line
    columnDefs: PropTypes.array.isRequired,
    // eslint-disable-next-line
    defaultColDef: PropTypes.object.isRequired,
    theme: Theme.isRequired,
    user: User.isRequired,
    // onCellEditingStopped: PropTypes.func.isRequired,
    apiEndPoint: PropTypes.string,
    showDatePicker: PropTypes.bool,
    singleDatePicker: PropTypes.bool,
    showImportButton: PropTypes.bool,
    showFooter: PropTypes.bool,
    parentProcessChange: PropTypes.func,
    parentDeleteRows: PropTypes.func,
    parentSelectionChanged: PropTypes.func,
    parentSetData: PropTypes.func,
    parentAccess: PropTypes.func,
    showAddRowButton: PropTypes.bool,
    showDeleteSelectedRowButton: PropTypes.bool,
    getNewRow: PropTypes.func,
    importMessage: PropTypes.string,
    uploadPath: PropTypes.string,
    apiQueryParams: PropTypes.string,
    enableCharts: PropTypes.bool,
    enableRangeSelection: PropTypes.bool,
    sideBar: PropTypes.bool,
    pivotMode: PropTypes.bool,
    // eslint-disable-next-line
    autoGroupColumnDef: PropTypes.object,
    suppressAggFuncInHeader: PropTypes.bool,
    getGroupRowAgg: PropTypes.func,
    // eslint-disable-next-line
    pivotButtons: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]),
    displayBy: PropTypes.func,
    tableHeight: PropTypes.string,
    parentControlledLoad: PropTypes.bool,
    showCornerButtons: PropTypes.bool,
    showExportOptions: PropTypes.bool,
    autoSizeToFit: PropTypes.bool,
    defaultStartDays: PropTypes.number,
    defaultEndDays: PropTypes.number,
    // eslint-disable-next-line
    productDetailOptions: PropTypes.object,
    rowDeselection: PropTypes.bool,
    frameworkComponents: PropTypes.instanceOf(Object),
    rowHeight: PropTypes.number,
    autoSizeAll: PropTypes.bool,
    pagination: PropTypes.bool,
    paginationPageSize: PropTypes.number,
    serverSideInfiniteScroll: PropTypes.bool,
    cacheBlockSize: PropTypes.number,
    rowModelType: PropTypes.string,
    groupDefaultExpanded: PropTypes.number,
    groupDisplayType: PropTypes.string,
    groupHideOpenParents: PropTypes.bool,
    datasource: PropTypes.instanceOf(Object),
    colDefUpdate: PropTypes.instanceOf(Object),
    rowData: PropTypes.instanceOf(Array),
    isLoading: PropTypes.func,
    loading: PropTypes.bool,
    confirmLogic: PropTypes.bool,
    onFirstDataRendered: PropTypes.func,
    editSelectedRowsButton: PropTypes.bool,
    openEditSelected: PropTypes.func,
    setColumnsFromObject: PropTypes.shape(),
    exportPrefix: PropTypes.string,
    startDate: PropTypes.string,
    customButton: PropTypes.shape(),
  };

  static defaultProps = {
    showDatePicker: false,
    singleDatePicker: false,
    getNewRow: null,
    parentProcessChange() {},
    parentDeleteRows() {},
    parentSelectionChanged() {},
    parentSetData() {},
    parentAccess() {},
    uploadPath: 'upload-csv',
    apiQueryParams: null,
    enableCharts: true,
    enableRangeSelection: true,
    sideBar: false,
    pivotMode: false,
    autoGroupColumnDef: undefined,
    suppressAggFuncInHeader: false,
    getGroupRowAgg: undefined,
    pivotButtons: [],
    defaultStartDays: 7,
    defaultEndDays: 1,
    productDetailOptions: {
      showButton: false,
      columnName: 'asin',
    },
    rowDeselection: false,
    frameworkComponents: {},
    rowHeight: 25,
    autoSizeAll: false,
    pagination: false,
    paginationPageSize: null,
    serverSideInfiniteScroll: false,
    cacheBlockSize: null,
    rowModelType: 'clientSide',
    groupDefaultExpanded: null,
    groupDisplayType: '',
    groupHideOpenParents: false,
    datasource: null,
    colDefUpdate: null,
    rowData: null,
    apiEndPoint: null,
    confirmLogic: false,
    editSelectedRowsButton: false,
    showImportButton: false,
    showFooter: false,
    showAddRowButton: false,
    showDeleteSelectedRowButton: false,
    showCornerButtons: true,
    showExportOptions: true,
    tableHeight: '75vh',
    importMessage: '',
    autoSizeToFit: false,
    displayBy: function displayBy(value, childThis) {
      childThis.gridColumnApi.setRowGroupColumns([value]);
    },
    parentControlledLoad: false,
    isLoading() {},
    loading: false,
    onFirstDataRendered() {},
    openEditSelected() {},
    setColumnsFromObject: { enabled: false, buildDynamicColumns: () => {} },
    exportPrefix: null,
    startDate: null,
    customButton: null,
  };

  constructor(props) {
    super(props);

    const { defaultStartDays, defaultEndDays, rowModelType } = this.props;

    this.state = {
      reportStartDate: moment().subtract(defaultStartDays, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(defaultEndDays, 'days').format('YYYY-MM-DD'),
      rows: null,
      rowDataModelType: rowModelType,
      isExporting: false,
      overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">No Rows to Display</span>',
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Ready for input</span>',
    };
  }

  componentDidMount() {
    const {
      productDetailOptions, editSelectedRowsButton, parentControlledLoad, parentAccess,
    } = this.props;
    if (productDetailOptions.showButton) {
      document.getElementById('showProductDetail').disabled = true;
    }
    if (editSelectedRowsButton) {
      document.getElementById('editSelectedRowsButton').disabled = true;
    }
    if (!parentControlledLoad) {
      this.refreshData();
    }
    parentAccess(this);
  }

  componentDidUpdate(prevState) {
    const {
      sellerSelect, apiQueryParams, apiEndPoint, datasource, parentControlledLoad, colDefUpdate,
    } = this.props;
    if ((sellerSelect.value !== prevState.sellerSelect.value && !parentControlledLoad) || apiQueryParams !== prevState.apiQueryParams || apiEndPoint !== prevState.apiEndPoint) {
      if (!datasource && (apiEndPoint !== null)) {
        this.refreshData();
      }
    }
    if (sellerSelect.value !== prevState.sellerSelect.value && datasource && this.gridApi) {
      this.gridApi.setServerSideDatasource(datasource);
    }
    if (sellerSelect.type !== prevState.sellerSelect.type && colDefUpdate && this.gridApi) {
      this.gridApi.setColumnDefs(colDefUpdate);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.sellerSelect !== prevState.sellerSelect) {
      return {
        sellerSelect: nextProps.sellerSelect,
        rowDataModelType: nextProps.rowModelType,
      };
    }
    // No change
    return null;
  }

  onBtExport() {
    const onlySelected = getBooleanValue('#onlySelected')
    const { rowDataModelType, rows } = this.state;

    const totalRows = this.gridApi.paginationGetRowCount();
    const pageSize = this.gridApi.paginationGetPageSize();
    const fullyLoaded = rows && rows.length === totalRows;

    if (rowDataModelType === 'clientSide' || onlySelected || fullyLoaded || totalRows < pageSize) {
      this.exportToCsv(onlySelected)
    } else {
      this.exportAllPagesToCsv(totalRows, pageSize)
    }
  }

  exportToCsv(onlySelected) {
    const { columnDefs } = this.props;
    const imageColumnDef = columnDefs.filter(item => item.field === 'img')[0];
    const imageColumn = imageColumnDef && imageColumnDef.field && this.gridColumnApi.getColumn(imageColumnDef.field);
    const fileName = this.getExportFileName();

    if (imageColumn) {
      this.gridColumnApi.setColumnVisible(imageColumn, false);
      this.gridApi.exportDataAsCsv({ onlySelected, fileName });
      this.gridColumnApi.setColumnVisible(imageColumn, true);
    } else {
      this.gridApi.exportDataAsCsv({ onlySelected, fileName });
    }
  }

  exportAllPagesToCsv(totalRows, pageSize) {
    const { datasource } = this.props;
    const totalPages = Math.ceil(totalRows / pageSize);

    const promises = []
    for (let i = 0; i < totalPages; i += 1) {
      const request = {
        startRow: i * pageSize,
        sortModel: '',
      }
      promises.push(new Promise((resolve, reject) => {
        const params = {
          request,
          success: (results) => {
            resolve(results);
          },
          fail: () => {
            reject();
          },
        }
        datasource.getRows(params);
      }));
    }

    this.setState(() => (
      {
        overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Preparing data for export...</span>',
      }
    ), () => {
      this.gridApi.showLoadingOverlay();
    });

    Promise
      .all(promises)
      .then((results) => {
        const fullResults = [];
        results.forEach(result => {
          fullResults.push(...result.rowData);
        })

        this.setState(() => ({
          rows: fullResults,
          rowDataModelType: 'clientSide',
          isExporting: true,
        }))
      })
      .catch(() => {
        this.gridApi.hideOverlay();
      });
  }

  getExportFileName() {
    const {
      exportPrefix, showDatePicker, singleDatePicker, startDate, endDate,
    } = this.props;
    const { reportStartDate, reportEndDate } = this.state;

    if (!exportPrefix) {
      return '';
    }

    let fileName = `${exportPrefix}`
    if (showDatePicker) {
      const start = moment(reportStartDate).format('YYYYMMDD');
      fileName = `${fileName}-${start}`

      if (!singleDatePicker && reportStartDate !== reportEndDate) {
        const end = moment(reportEndDate).format('YYYYMMDD');
        fileName = `${fileName}-${end}`
      }
    } else if (startDate) {
      // not using table date picker, but dates were provided
      const start = moment(startDate).format('YYYYMMDD');
      fileName = `${fileName}-${start}`

      if (endDate) {
        const end = moment(endDate).format('YYYYMMDD');
        if (end !== start) {
          fileName = `${fileName}-${end}`;
        }
      }
    }

    return `${fileName}.csv`
  }

  // onBtShowLoading() {
  //   this.gridApi.showLoadingOverlay();
  // }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.refreshData);
  }

  refreshData = () => {
    const {
      reportStartDate, reportEndDate,
    } = this.state;
    const {
      user, sellerSelect, apiEndPoint, apiQueryParams, showDatePicker, singleDatePicker, parentSetData, datasource, isLoading, setColumnsFromObject,
    } = this.props;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const defaultQueryParams = `accountId=${sellerSelect.value.toString()}&sellerAccountId=${sellerSelect.value.toString()}&parentAccountId=${sellerSelect.parentAccountId}&currencyCode=${(sellerSelect.currencyCode || 'USD')}&accountType=${sellerSelect.type}`;

    let dateParams = '';
    if (showDatePicker) {
      if (singleDatePicker) {
        dateParams = `&theDate=${reportStartDate}`;
      } else {
        dateParams = `&startDate=${reportStartDate}&endDate=${reportEndDate}`;
      }
    }

    isLoading(true);

    const agGridThis = this;
    const joinChar = apiEndPoint.includes('?') ? '&' : '?';

    const getUrl = `${apiEndPoint}${joinChar}${apiQueryParams || defaultQueryParams}${dateParams}`;

    async function fetchData(url, options) {
      try {
        const data = await doFetch(url, options);
        let rows = data;
        if (data.tableData) {
          rows = data.tableData;
        }
        parentSetData(data, agGridThis);

        if (rows.length > 0) {
          if (setColumnsFromObject?.enabled) {
            const colDefs = agGridThis.gridApi.getColumnDefs();
            colDefs.length = 0;
            agGridThis.gridApi.setColumnDefs(setColumnsFromObject.buildDynamicColumns(colDefs, rows[0]));
          }

          isLoading(false);
          return {
            rows,
          };
        }
        isLoading(false);
        return {
          rows: null,
          // Set noRowsTemplate in case there isn't any data.
          overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">No Rows to Display</span>',
        };
      } catch (error) {
        isLoading(false);
        return {
          rows: null,
          overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">Error Pulling Rows</span>',
        };
      }
    }

    // if we are using datasource, we should just update that on a refresh.
    if (datasource && this.gridApi) {
      this.gridApi.setServerSideDatasource(datasource);
    } else if (sellerSelect.value > 0 && reportStartDate && reportEndDate) {
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };
      this.setState({
        rows: [],
        // Using this to control loading messaging as the overlayLoadingTemplate only works on inital load.
        overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading...</span>',
        overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading...</span>',
      });

      // Async IIFE lets us use async/await here without having to async this entire function
      (async (thisRef, url, options) => {
        // fetchData catches errors and returns error state so we don't have to worry about it here
        thisRef.setState(await fetchData(url, options));
      })(this, getUrl, requestOptions);
    } else {
      this.setState({
        rows: [],
        // Using this to control loading messaging as the overlayLoadingTemplate only works on inital load.
        overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please select a account.</span>',
      });
    }
  }

  onGridReady = (params) => {
    const {
      autoSizeToFit, autoSizeAll, datasource, rowData,
    } = this.props;
    const {
      rows, rowDataModelType, isExporting,
    } = this.state;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (!rows) {
      this.gridApi.showLoadingOverlay();
    }
    if (rowDataModelType === 'serverSide' && datasource) {
      this.gridApi.setServerSideDatasource(datasource);
    }
    if (autoSizeToFit) {
      this.gridApi.sizeColumnsToFit();
    }
    if (autoSizeAll) {
      this.autoSizeAll();
    }
    if (rowData) {
      this.setState({ rows: rowData });
    }

    if (isExporting) {
      this.exportToCsv(false);
      this.gridApi.hideOverlay();
      this.setState({ isExporting: false })
    }
  }

  rowValueChanged = (rowData) => {
    const {
      user, parentProcessChange, sellerSelect, apiEndPoint, confirmLogic,
    } = this.props;
    const rowNode = this.gridApi.getDisplayedRowAtIndex(rowData.rowIndex);
    if (confirmLogic) {
      // eslint-disable-next-line
      if (window.confirm('Confirm Change')) {
        parentProcessChange(rowNode, user, sellerSelect, apiEndPoint, this);
      }
    }
  }

  createNewRow = () => {
    const { getNewRow } = this.props;
    this.gridApi.applyTransaction({ add: [getNewRow()], addIndex: 0 });
  }

  deleteSelectedRow = () => {
    const {
      user, parentDeleteRows, apiEndPoint,
    } = this.props;
    const selectedRows = this.gridApi.getSelectedRows();
    parentDeleteRows(selectedRows, user, apiEndPoint, this);
  }

  onSelectionChanged = () => {
    const { productDetailOptions, editSelectedRowsButton, parentSelectionChanged } = this.props;
    const selectedRows = this.gridApi.getSelectedRows();
    if (productDetailOptions.showButton) {
      const button = document.getElementById('showProductDetail');
      if (selectedRows.length === 1) {
        button.classList.remove('btn-secondary');
        button.classList.add('btn-primary');
        button.disabled = false;
      } else {
        button.classList.remove('btn-primary');
        button.classList.add('btn-secondary');
        button.disabled = true;
      }
    }
    if (editSelectedRowsButton) {
      const button = document.getElementById('editSelectedRowsButton');
      if (selectedRows.length >= 1) {
        button.classList.remove('btn-secondary');
        button.classList.add('btn-primary');
        button.disabled = false;
      } else {
        button.classList.remove('btn-primary');
        button.classList.add('btn-secondary');
        button.disabled = true;
      }
    }
    parentSelectionChanged(selectedRows, this);
  }

  createPivotButtons = () => {
    const { pivotButtons, displayBy } = this.props;
    const buttonList = [];
    if (pivotButtons) {
      pivotButtons.forEach((pivotButton) => {
        buttonList.push(<button key={pivotButton.buttonName} type="submit" className="btn btn-primary" onClick={displayBy.bind(this, pivotButton.buttonAction, this)}>{pivotButton.buttonName}</button>);
      });
    }
    return buttonList;
  }

  getMarketPlace = (name) => {
    let result = countryOptionDefault.marketplace;
    countryOptionsArray.forEach((country) => {
      if (name === country.value) {
        result = country.marketplace;
      }
    });
    return result;
  }

  autoSizeAll() {
    const colApi = this.gridColumnApi;
    colApi
      .autoSizeColumns(
        colApi.getColumns().map(column => column.colId),
      );
  }

  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }

  openPdPage() {
    const { productDetailOptions } = this.props;
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length === 1) {
      const asin = selectedRows[0][productDetailOptions.columnName];
      const { sellerSelect } = this.props;
      const { mpName } = sellerSelect;
      const marketplace = this.getMarketPlace(mpName);
      const url = `https://${marketplace}/dp/${asin}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  render() {
    const {
      reportStartDate, reportEndDate, rows, overlayNoRowsTemplate, overlayLoadingTemplate, rowDataModelType,
    } = this.state;
    const {
      showDatePicker, singleDatePicker, showImportButton, showFooter, theme, showAddRowButton, showDeleteSelectedRowButton, importMessage,
      uploadPath, columnDefs, defaultColDef, enableCharts, enableRangeSelection, sideBar, pivotMode, autoGroupColumnDef,
      suppressAggFuncInHeader, getGroupRowAgg, tableHeight, showCornerButtons, showExportOptions, productDetailOptions, editSelectedRowsButton, openEditSelected, customButton,
      rowDeselection, frameworkComponents, rowHeight, pagination, paginationPageSize, cacheBlockSize, serverSideInfiniteScroll, onFirstDataRendered,
      groupHideOpenParents, groupDisplayType, groupDefaultExpanded, loading,
    } = this.props;

    let recordCount = 0;
    if (rows) {
      recordCount = rows.length;
    }

    let useTheme = 'ag-theme-balham';
    if (!theme.className.endsWith('light')) {
      useTheme = 'ag-theme-balham-dark';
    }

    const componentKey = `table_${rowDataModelType}_key`

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md={6}>
                {showDatePicker && !singleDatePicker && (
                  <DateRangePicker
                    startDate={moment(reportStartDate).toDate()}
                    endDate={moment(reportEndDate).toDate()}
                    onDateChange={this.onDateChange}
                    loading={loading}
                  />
                )}
                {showDatePicker && singleDatePicker && (
                  <DatePicker
                    theDate={moment(reportStartDate).toDate()}
                    onDateChange={this.onDateChange}
                    loading={loading}
                  />
                )}
              </Col>
              <Col xl={6} style={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px' }}>
                <ButtonToolbar className="form__button-toolbar" style={{ marginLeft: 'auto' }}>
                  {showImportButton && (
                    <Modal
                      color="primary"
                      title="Import CSV File (Max 32MB)"
                      btn="Import CSV"
                      message={importMessage}
                      refreshData={this.refreshData}
                      uploadPath={uploadPath}
                    />
                  )}
                  {showCornerButtons && (
                    <div>
                      {showAddRowButton && (
                        <button type="submit" className="btn btn-primary" onClick={this.createNewRow.bind(this)}>Create New Row</button>
                      )}
                      {showDeleteSelectedRowButton && (
                        <button type="submit" className="btn btn-primary" onClick={this.deleteSelectedRow.bind(this)}>Delete Selected Row</button>
                      )}
                      {productDetailOptions.showButton && (
                        <button id="showProductDetail" type="button" className="btn btn-primary" onClick={this.openPdPage.bind(this)}>Product Detail</button>
                      )}
                      {customButton && (
                        <button id={customButton.id} type="button" className="btn btn-primary" onClick={customButton.function.bind(this)}>{customButton.text}</button>
                      )}
                      {editSelectedRowsButton && (
                        <button id="editSelectedRowsButton" type="button" className="btn btn-primary" onClick={openEditSelected.bind(this)}>Edit Selected Rows</button>
                      )}
                      <button type="submit" className="btn btn-primary" onClick={this.sizeToFit.bind(this)}>Size to Fit</button>
                      <button type="submit" className="btn btn-primary" onClick={this.autoSizeAll.bind(this)}>Auto-Size All</button>
                      <button type="submit" className="btn btn-primary" onClick={this.onBtExport.bind(this)}>Export to CSV</button>
                    </div>
                  )}
                  {showCornerButtons && showExportOptions && (
                    <div>
                      <p>Export Options: <br /> <input type="checkbox" id="onlySelected" /> Only Selected</p>
                    </div>
                  )}
                  {showCornerButtons && !showExportOptions && (
                    <input type="hidden" id="onlySelected" />
                  )}
                </ButtonToolbar>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {this.createPivotButtons()}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className={useTheme} style={{ height: tableHeight, width: '100%' }}>
                  <AgGridReact
                    key={componentKey}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={rows}
                    rowSelection="multiple"
                    onGridReady={this.onGridReady}
                    groupIncludeTotalFooter={showFooter}
                    getGroupRowAgg={getGroupRowAgg}
                    onRowValueChanged={this.rowValueChanged}
                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                    overlayLoadingTemplate={overlayLoadingTemplate}
                    enableCharts={enableCharts}
                    enableRangeSelection={enableRangeSelection}
                    sideBar={sideBar}
                    pivotMode={pivotMode}
                    editType="fullRow"
                    autoGroupColumnDef={autoGroupColumnDef}
                    suppressAggFuncInHeader={suppressAggFuncInHeader}
                    onSelectionChanged={this.onSelectionChanged}
                    rowDeselection={rowDeselection}
                    components={frameworkComponents}
                    rowHeight={rowHeight}
                    getRowHeight={() => rowHeight}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    cacheBlockSize={cacheBlockSize}
                    rowModelType={rowDataModelType}
                    serverSideInfiniteScroll={serverSideInfiniteScroll}
                    onFirstDataRendered={onFirstDataRendered}
                    groupDefaultExpanded={groupDefaultExpanded}
                    groupDisplayType={groupDisplayType}
                    groupHideOpenParents={groupHideOpenParents}
                  />
                </div>
                {!pagination && (
                  <div>
                    <p>Total Row Count: {recordCount}</p>
                  </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;
  const { theme } = state;

  return {
    sellerSelect,
    user,
    theme,
  };
};

export default connect(mapStateToProps)(TableBuilder);
