import React, { useEffect } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DashboardContextProvider, useDashboardContext } from './components/DashboardContext';
import useWalmartData from './components/useWalmartData';
import CardPanel from '../../../shared/components/widgets/CardPanel';
import DateRangePicker from '../../../shared/components/widgets/DateRangePicker';
import AccountSelect from './components/AccountSelect';
import ExecutiveView from './components/ExecutiveView';
import DateViewFilter from '../../../shared/components/filters/DateViewFilter';
import Spend from './components/Spend';
import Sales from './components/Sales';
import PPCAcos from './components/PPCAcos';
import SalesGraph from './components/SalesGraph';
import ItemTopPerformance from './components/ItemTopPerformance';
import { when } from '../../../shared/functions';
import SpendGraph from './components/SpendGraph';

function WalmartDashboardPage() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const { state, actions } = useDashboardContext();
  const {
    accountSelect, startSelectionDate, endSelectionDate, dateView, performanceSelect,
  } = state;
  const {
    setDates, setAccount, setDateView,
  } = actions;
  const [loading, results, error] = useWalmartData(accountSelect, startSelectionDate, endSelectionDate, dateView, performanceSelect);

  useEffect(() => {
    when(error, () => {
      console.error(error);
    });
  }, [error]);

  useEffect(() => {
    if (selectedSeller && Object.keys(selectedSeller).length > 0 && selectedSeller.value > 0) {
      setAccount(selectedSeller);
    }
  }, [selectedSeller]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6} lg={4}>
          <Spend
            data={results && results.data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
            dateFieldName="displayDate"
          />
        </Col>
        <Col md={6} lg={4}>
          <Sales
            data={results && results.data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
            dateFieldName="displayDate"
          />
        </Col>
        <Col md={6} lg={4}>
          <PPCAcos
            data={results && results.data}
            loading={loading}
            dateView={dateView}
            dateFieldName="displayDate"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SalesGraph
            title="PPC Sales"
            subhead="By Placement"
            loading={loading}
            data={results && results.pieData}
            account={accountSelect}
          />
        </Col>
        <Col md={6}>
          <SpendGraph
            title="PPC Spend"
            subhead="By Placement"
            loading={loading}
            data={results && results.pieData}
            account={accountSelect}
          />
        </Col>
        <Col md={6}>
          <ItemTopPerformance
            loading={loading}
            title="Best Performers -  Orders"
            subtitle={'By Average Orders for Date Range'}
            data={results && results.topItemOrders}
            fields={['orders', 'acos']}
          />
        </Col>
        <Col md={6}>
          <ItemTopPerformance
            loading={loading}
            title="Best Performers - Sales"
            subtitle={'By Average Sales for Date Range'}
            data={results && results.topItemSales}
            fields={['sales', 'spend']}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <CardPanel
            showButtons={false}
          >
            <Row>
              <Col xs={12} lg={4} xl={7}>
                <DateRangePicker
                  label="Select Dates:"
                  startDate={startSelectionDate.toDate()}
                  endDate={endSelectionDate.toDate()}
                  onDateChange={(start, end) => setDates(moment(start), moment(end))}
                />
              </Col>
              <Col xs={12} lg={4} xl={3}>
                <AccountSelect label="Dashboard Account:" accountSelect={accountSelect} onChange={account => setAccount(account)} />
              </Col>
              <Col xs={12} lg={4} xl={2}>
                <DateViewFilter value={dateView} onChange={view => setDateView(view)} />
              </Col>
            </Row>
          </CardPanel>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ExecutiveView
            title="Ad Sales vs Ad Spend"
            loading={loading}
            account={accountSelect}
            data={results && results.data}
            dateView={dateView}
            isWalmart
          />
        </Col>
      </Row>
    </Container>
  );
}

export default function WalmartDashboard() {
  return (
    <DashboardContextProvider>
      <WalmartDashboardPage />
    </DashboardContextProvider>
  );
}
