import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BudgetGroupLandingPages from './components/BudgetGroupLandingPages';

export default function CatalogIndexPage() {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Amazon Budget Group Landing Pages</h3>
        </Col>
      </Row>
      <Row>
        <BudgetGroupLandingPages />
      </Row>
    </Container>
  );
}
