import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import moment from 'moment';
import config from '../../../../config';
import CardPanel from '../../../../shared/components/widgets/CardPanel';
import ChartBuilder from '../../../../shared/components/chart/ChartBuilder';
import DateRangePicker from '../../../../shared/components/widgets/DateRangePicker';
import DateViewFilter, { DateViewOptions } from '../../../../shared/components/filters/DateViewFilter';
import NewFilterSelect from '../../../../shared/components/filters/FilterSelect';
import { currencyFormatterDirect } from '../../../../shared/components/table/functions';

const Calendar = () => {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const [accountId, setAccountId] = useState(selectedSeller.value);
  const [forecastData, setForecastData] = useState([]);
  const [budgetGroupOptions, setBudgetGroupOptions] = useState([{ label: 'ALL', value: '' }]);
  const [budgetGroupOption, setBudgetGroupOption] = useState({ label: 'ALL', value: '' });
  const [loading, setLoading] = useState(false);
  const [dateView, setDateView] = useState(DateViewOptions.DAY);
  const [dates, setDates] = useState({ start: moment(), end: moment().endOf('month') });
  const chartTheme = theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark';

  const predefinedRanges = [
    {
      label: 'Today',
      value: [moment().toDate(), moment().toDate()],
      placement: 'left',
    },
    {
      label: 'Next 30 days',
      value: [moment().add(1, 'days').toDate(), moment().add(90, 'days').toDate()],
      placement: 'left',
    },
    {
      label: 'Next 90 days',
      value: [moment().add(1, 'days').toDate(), moment().add(90, 'days').toDate()],
      placement: 'left',
    },
    {
      label: 'This year',
      value: [moment().toDate(), moment().endOf('year').toDate()],
      placement: 'left',
    },
  ]

  const renderTooltip = ({ datum, yKey }) => ({
    content: `${datum[dateView.value]} : ${currencyFormatterDirect(Math.round(datum[yKey]))}${datum.noofDays !== datum.expectedDays ? '*' : ''}`,
  });

  const formatLabel = ({ datum }) => (datum.noofDays !== datum.expectedDays ? '*' : '');

  const chartOptions = {
    theme: chartTheme,
    data: forecastData,
    series: [
      { type: 'bar', xKey: dateView.value, stackGroup: dateView.value, yKey: 'baseBudget', yName: 'Base', tooltip: { renderer: renderTooltip }, label: { formatter: formatLabel } },
      { type: 'bar', xKey: dateView.value, stackGroup: dateView.value, yKey: 'eventBudget', yName: 'Event', tooltip: { renderer: renderTooltip }, label: { formatter: formatLabel } },
    ],
    title: {
      text: 'Estimated Budget By Day',
    },
  };

  useEffect(() => {
    if (selectedSeller.value !== accountId) {
      setBudgetGroupOptions([{ label: 'ALL', value: '' }]);
      setBudgetGroupOption({ label: 'ALL', value: '' });
      setAccountId(selectedSeller.value);
    }
  }, [selectedSeller]);

  useEffect(() => {
    setLoading(true);

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const getUrl = `${config.serverUrl}/accounts/budget-daily-forecast?accountId=${accountId}&retailer=amazon&budgetGroupDailyId=${budgetGroupOption.value}&startDate=${moment(dates.start).format('YYYY-MM-DD')}&endDate=${moment(dates.end).format('YYYY-MM-DD')}&dateView=${dateView.value}`;

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    fetch(getUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        setForecastData(data.forecastData);
        if (budgetGroupOption.value === '') {
          const budgetGroupOptionsFromData = [{ label: 'ALL', value: '' }];
          data.budgetGroupData.forEach(bg => {
            budgetGroupOptionsFromData.push({ label: bg.name, value: bg.id.toString() });
          })
          setBudgetGroupOptions(budgetGroupOptionsFromData);
        }
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
  }, [accountId, dateView, dates, budgetGroupOption]);

  return (
    <Container>
      <CardPanel
        showButtons={false}
        parentRefresh={loading}
      >
        <Row>
          <Col md={12}>
            <h3 className="page-title">Budget Forecast</h3>
            <h3 className="page-subhead subhead">Forecast your budget usage with your current budget settings and events</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={3} xl={3}>
            <DateRangePicker
              label="Select Dates:"
              startDate={dates.start.toDate()}
              endDate={dates.end.toDate()}
              onDateChange={(start, end) => setDates({ start: moment(start), end: moment(end) })}
              allowedRange={{ start: moment().toDate(), end: moment().add(1, 'year').toDate() }}
              dynamicPredefinedRanges={predefinedRanges}
            />
          </Col>
          <Col xs={12} lg={4} xl={4}>
            <DateViewFilter
              value={dateView}
              onChange={newView => setDateView(newView)}
              disable={loading}
            />
          </Col>
          <Col xs={12} lg={4} xl={4}>
            <NewFilterSelect
              label="Budget Group:"
              onChange={setBudgetGroupOption}
              options={budgetGroupOptions}
              selected={budgetGroupOption}
              disable={loading}
            />
          </Col>
        </Row>
        <Row>
          <ChartBuilder chartOptions={chartOptions} parentRefresh={loading} chartHeight="500px" />
          <br />
          <p>* Partial Data due to date range selected</p>
          <p>** Budgets are rounded to a whole number</p>
        </Row>
      </CardPanel>
    </Container>
  );
}

export default Calendar;
