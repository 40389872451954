import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import CustomerSellerSelect from './CustomerSellerSelect';
import { SiteImage } from '../../../shared/components/domainSupport';

export default function Topbar({
  changeMobileSidebarVisibility, changeSidebarVisibility,
}) {
  const seller = useSelector(state => state.sellerSelect);

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <SiteImage logoWidth="150" logoHeight="32" link="/" />
        </div>
        {seller.value === 274 && (
          <div className="topbar__center">
            <Link className="topbar__logo" to="/" />
          </div>
        )}
        <div className="topbar__right">
          <CustomerSellerSelect />
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
}

Topbar.propTypes = {
  changeSidebarVisibility: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};
