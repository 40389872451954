import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Table } from 'reactstrap';
import moment from 'moment';
import Panel from '../../../../../shared/components/Panel';
import { formatNumberWhole, currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberOnePlace } from '../../../../../shared/components/table/functions';
import { serverUrl, jsonHeader } from '../../../../../config';
import { MetricOptions } from '../../../../../shared/components/filters/SalesMetricFilter';
import ReportFilters, { defaultFilters } from '../../../../../shared/components/filters/ReportFilters';
import ChartBuilder from '../../../../../shared/components/chart/ChartBuilder';
import { usePrevious, history } from '../../../../../helpers';

export default function BccAccountPerformance() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const chartTheme = theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark';

  const [bccAccountPerformance, setBccAccountPerformance] = useState([]);
  const [bccAccountPerformanceHold, setBccAccountPerformanceHold] = useState([]);
  const [bccAccountDailyTotalsMap, setBccAccountDailyTotalsMap] = useState(new Map());
  const [bccAccountTotals, setBccAccountTotals] = useState({});

  const [filtersConfig, setFiltersConfig] = useState(defaultFilters);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [userDateRequest, setUserDateRequest] = useState(false);
  const [partialDataNote, setPartialDataNote] = useState(false);

  const filtersConfigPrev = usePrevious(filtersConfig);
  const itemName = selectedSeller.type === 1 ? 'SKU' : 'ASIN';
  const defaultStartDate = moment().subtract(34, 'days');
  const defaultEndDate = moment().subtract(4, 'days');

  const functionFormatter = (itemType) => {
    let tickFormatter = number => formatNumberWhole(number);

    if ([MetricOptions.ACOS.value, MetricOptions.CVR.value, MetricOptions.CTR.value].includes(itemType)) {
      tickFormatter = percent => formatDecimalToFixedTwoPercent({ value: percent });
    }
    if ([MetricOptions.SALES.value, MetricOptions.SPEND.value].includes(itemType)) {
      tickFormatter = dollar => currencyFormatter({ value: dollar });
    }
    if ([MetricOptions.ROAS.value].includes(itemType)) {
      tickFormatter = number => formatNumberOnePlace(number);
    }
    return tickFormatter;
  }

  const getPivotData = (data, pivotOn) => {
    const pivotData = data.reduce((list, row) => {
      const currentObj = list.get(row.searchDate);
      if (currentObj) {
        currentObj[row.brand_cat_comp] = row[pivotOn];
        list.set(row.searchDate, currentObj);
      } else {
        const newObj = {
          searchDate: row.searchDate,
          noofDays: row.noofDays,
          expectedDays: row.expectedDays,
          [row.brand_cat_comp]: row[pivotOn],
        };
        list.set(row.searchDate, newObj);
      }
      return list;
    }, new Map());

    const result = Array.from(pivotData.values());
    return result;
  }

  const getDailyTotalsMap = (data) => {
    const dailyTotalsMap = data.reduce((list, row) => {
      if (row.brand_cat_comp !== '') { // Don't count rows that are not labeled (although they should be labeled!)
        const currentObj = list.get(row.searchDate);
        if (currentObj) {
          currentObj.impressions += row.impressions;
          currentObj.clicks += row.clicks;
          currentObj.orders += row.orders;
          currentObj.spend += row.spend;
          currentObj.sales += row.sales;
          currentObj.acos = (currentObj.sales > 0) ? (currentObj.spend / currentObj.sales) : 0;
          currentObj.roas = (currentObj.spend > 0) ? (currentObj.sales / currentObj.spend) : 0;
          currentObj.cvr = (currentObj.clicks > 0) ? (currentObj.orders / currentObj.clicks) : 0;
          currentObj.ctr = (currentObj.impressions > 0) ? (currentObj.clicks / currentObj.impressions) : 0;
          list.set(row.searchDate, currentObj);
        } else {
          const newObj = {
            searchDate: row.searchDate,
            impressions: row.impressions,
            clicks: row.clicks,
            orders: row.orders,
            spend: row.spend,
            sales: row.sales,
            acos: (row.sales > 0) ? (row.spend / row.sales) : 0,
            roas: (row.spend > 0) ? (row.sales / row.spend) : 0,
            cvr: (row.clicks > 0) ? (row.orders / row.clicks) : 0,
            ctr: (row.impressions > 0) ? (row.clicks / row.impressions) : 0,
          };
          list.set(row.searchDate, newObj);
        }
      }
      return list;
    }, new Map());

    return dailyTotalsMap;
  }

  const getTotals = (data) => {
    const totalObj = {
      impressions: 0,
      clicks: 0,
      orders: 0,
      spend: 0,
      sales: 0,
      acos: 0,
      roas: 0,
      cvr: 0,
      ctr: 0,
    };

    const brandTotalObj = { ...totalObj };
    const competitorTotalObj = { ...totalObj };
    const categoryTotalObj = { ...totalObj };

    data.forEach((row) => {
      if (row.brand_cat_comp !== '') { // Don't count rows that are not labeled (although they should be labeled!)
        totalObj.impressions += row.impressions;
        totalObj.clicks += row.clicks;
        totalObj.orders += row.orders;
        totalObj.spend += row.spend;
        totalObj.sales += row.sales;
        totalObj.acos = (totalObj.sales > 0) ? (totalObj.spend / totalObj.sales) : 0;
        totalObj.roas = (totalObj.spend > 0) ? (totalObj.sales / totalObj.spend) : 0;
        totalObj.cvr = (totalObj.clicks > 0) ? (totalObj.orders / totalObj.clicks) : 0;
        totalObj.ctr = (totalObj.impressions > 0) ? (totalObj.clicks / totalObj.impressions) : 0;
        if (row.brand_cat_comp === 'Brand') {
          brandTotalObj.impressions += row.impressions;
          brandTotalObj.clicks += row.clicks;
          brandTotalObj.orders += row.orders;
          brandTotalObj.spend += row.spend;
          brandTotalObj.sales += row.sales;
          brandTotalObj.acos = (brandTotalObj.sales > 0) ? (brandTotalObj.spend / brandTotalObj.sales) : 0;
          brandTotalObj.roas = (brandTotalObj.spend > 0) ? (brandTotalObj.sales / brandTotalObj.spend) : 0;
          brandTotalObj.cvr = (brandTotalObj.clicks > 0) ? (brandTotalObj.orders / brandTotalObj.clicks) : 0;
          brandTotalObj.ctr = (brandTotalObj.impressions > 0) ? (brandTotalObj.clicks / brandTotalObj.impressions) : 0;
        }
        if (row.brand_cat_comp === 'Competitor') {
          competitorTotalObj.impressions += row.impressions;
          competitorTotalObj.clicks += row.clicks;
          competitorTotalObj.orders += row.orders;
          competitorTotalObj.spend += row.spend;
          competitorTotalObj.sales += row.sales;
          competitorTotalObj.acos = (competitorTotalObj.sales > 0) ? (competitorTotalObj.spend / competitorTotalObj.sales) : 0;
          competitorTotalObj.roas = (competitorTotalObj.spend > 0) ? (competitorTotalObj.sales / competitorTotalObj.spend) : 0;
          competitorTotalObj.cvr = (competitorTotalObj.clicks > 0) ? (competitorTotalObj.orders / competitorTotalObj.clicks) : 0;
          competitorTotalObj.ctr = (competitorTotalObj.impressions > 0) ? (competitorTotalObj.clicks / competitorTotalObj.impressions) : 0;
        }
        if (row.brand_cat_comp === 'Category') {
          categoryTotalObj.impressions += row.impressions;
          categoryTotalObj.clicks += row.clicks;
          categoryTotalObj.orders += row.orders;
          categoryTotalObj.spend += row.spend;
          categoryTotalObj.sales += row.sales;
          categoryTotalObj.acos = (categoryTotalObj.sales > 0) ? (categoryTotalObj.spend / categoryTotalObj.sales) : 0;
          categoryTotalObj.roas = (categoryTotalObj.spend > 0) ? (categoryTotalObj.sales / categoryTotalObj.spend) : 0;
          categoryTotalObj.cvr = (categoryTotalObj.clicks > 0) ? (categoryTotalObj.orders / categoryTotalObj.clicks) : 0;
          categoryTotalObj.ctr = (categoryTotalObj.impressions > 0) ? (categoryTotalObj.clicks / categoryTotalObj.impressions) : 0;
        }
      }
    });

    return {
      total: totalObj,
      brand: brandTotalObj,
      competitor: competitorTotalObj,
      category: categoryTotalObj,
    };
  }

  const renderTooltip = ({ datum, yKey, xKey }) => ({
    content: `${datum[xKey]}: ${functionFormatter(filtersConfig.metricView.value)(datum[yKey])}`,
  });

  const formatLabel = ({ datum }) => {
    if (datum.noofDays !== datum.expectedDays) {
      setPartialDataNote(true);
      return '*';
    }
    return '';
  };

  const chartOptions = {
    theme: chartTheme,
    data: bccAccountPerformance,
    series: [
      { type: 'bar', xKey: 'searchDate', stackGroup: filtersConfig.metricView.value, yKey: 'Brand', yName: 'Brand', tooltip: { renderer: renderTooltip }, label: { formatter: formatLabel } },
      { type: 'bar', xKey: 'searchDate', stackGroup: filtersConfig.metricView.value, yKey: 'Competitor', yName: 'Competitor', tooltip: { renderer: renderTooltip }, label: { formatter: formatLabel } },
      { type: 'bar', xKey: 'searchDate', stackGroup: filtersConfig.metricView.value, yKey: 'Category', yName: 'Category', tooltip: { renderer: renderTooltip }, label: { formatter: formatLabel } },
    ],
    title: {
      text: filtersConfig.metricView.label,
    },
    axes: [
      {
        type: 'category',
        position: 'bottom',
      },
      {
        type: 'number',
        position: 'left',
        label: {
          enabled: !([MetricOptions.ACOS.value, MetricOptions.CVR.value, MetricOptions.ROAS.value, MetricOptions.CTR.value].includes(filtersConfig.metricView.value)),
          formatter: (params) => functionFormatter(filtersConfig.metricView.value)(params.value),
        },
      },
    ],
  };

  useEffect(() => {
    // If statment tso we don't load the default dates
    if ((moment(filtersConfig.dateRange.start).format('YYYYMMDD') === moment(defaultStartDate).format('YYYYMMDD') && moment(filtersConfig.dateRange.end).format('YYYYMMDD') === moment(defaultEndDate).format('YYYYMMDD')) || userDateRequest) {
      if (filtersConfig.metricView && filtersConfigPrev && filtersConfigPrev.metricView.value !== filtersConfig.metricView.value) {
        const bccAccountPerformanceMetricViewChange = getPivotData(bccAccountPerformanceHold, filtersConfig.metricView.value);
        setBccAccountPerformance(bccAccountPerformanceMetricViewChange);
      } else {
        setUserDateRequest(true);
        setPartialDataNote(false);
        setLoadingData(true);
        const bccAccountPerformanceUrl = `${serverUrl}/amazon/bcc-account-performance?accountId=${selectedSeller.value}&parentAccountId=${selectedSeller.parentAccountId}&accountType=${selectedSeller.type}&filters=${encodeURIComponent(JSON.stringify(filtersConfig))}`;
        const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user.jwtToken}` };
        const requestOptions = {
          method: 'GET',
          headers: headerWithAuth,
        };

        fetch(bccAccountPerformanceUrl, requestOptions)
          .then((results) => {
            if (results.status === 401) {
              history.push('/logout');
            }
            if (!results.ok) {
              throw Error(results.statusText);
            }
            return results.json();
          }).then((data) => {
            const bccAccountTotalsData = getTotals(data);
            const bccAccountDailyTotalsMapData = getDailyTotalsMap(data);
            const bccAccountPerformanceData = getPivotData(data, filtersConfig.metricView.value);

            setBccAccountTotals(bccAccountTotalsData);
            setBccAccountDailyTotalsMap(bccAccountDailyTotalsMapData);
            setBccAccountPerformance(bccAccountPerformanceData);
            setBccAccountPerformanceHold(data);
            setLoadingData(false);
          });
      }
    }
  }, [selectedSeller, filtersConfig]);

  return (
    <Container className="dashboard">
      <ReportFilters
        setFiltersConfig={setFiltersConfig}
        itemName={itemName}
        loading={loadingFilters || loadingData}
        setLoading={setLoadingFilters}
        defaultDateRange={{ start: defaultStartDate, end: defaultEndDate }}
        filterCsv="dataSource,dateView,dates,metric,bcc,dataSet,portfolio,brand,subcategory,budgetGroup,showAds,items"
      />
      <Panel
        lg={12}
        md={12}
        title=""
        subhead=""
        parentRefresh={loadingData}
        showRefreshButton={false}
      >
        <Row>
          <ChartBuilder chartOptions={chartOptions} parentRefresh={loadingData} chartHeight="500px" />
          {partialDataNote && (<p>* Partial Data due to date range selected</p>)}
        </Row>
      </Panel>
      <Panel
        lg={12}
        md={12}
        title=""
        subhead=""
        parentRefresh={loadingData}
        showRefreshButton={false}
      >
        <div style={{
          marginLeft: '100px',
        }}
        >
          {(bccAccountPerformance.length > 0) && (
            <Table responsive striped className="dashboard__table-orders" id="dataOverTime">
              <thead>
                <tr>
                  <th className="sticky-th" />
                  {bccAccountPerformance.map(item => (
                    <th key={item.searchDate}>{item.searchDate}</th>
                  ))}
                  <th className="sticky-th-right">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="sticky-td-color" style={{ marginTop: '1px' }}>Brand</td>
                  {bccAccountPerformance.map(item => (
                    <td key={item.searchDate}>{functionFormatter(filtersConfig.metricView.value)(item.Brand)}</td>
                  ))}
                  <td>{functionFormatter(filtersConfig.metricView.value)(bccAccountTotals.brand[filtersConfig.metricView.value])}</td>
                </tr>
                <tr>
                  <td className="sticky-td">Competitor</td>
                  {bccAccountPerformance.map(item => (
                    <td key={item.searchDate}>{functionFormatter(filtersConfig.metricView.value)(item.Competitor)}</td>
                  ))}
                  <td>{functionFormatter(filtersConfig.metricView.value)(bccAccountTotals.competitor[filtersConfig.metricView.value])}</td>
                </tr>
                <tr>
                  <td className="sticky-td-color">Category</td>
                  {bccAccountPerformance.map(item => (
                    <td key={item.searchDate}>{functionFormatter(filtersConfig.metricView.value)(item.Category)}</td>
                  ))}
                  <td>{functionFormatter(filtersConfig.metricView.value)(bccAccountTotals.category[filtersConfig.metricView.value])}</td>
                </tr>
                <tr>
                  <td className="sticky-td">TOTAL</td>
                  {!loadingData && bccAccountPerformance.map(item => (
                    <td key={item.searchDate}>{functionFormatter(filtersConfig.metricView.value)(bccAccountDailyTotalsMap.get(item.searchDate)[filtersConfig.metricView.value])}</td>
                  ))}
                  <td>{functionFormatter(filtersConfig.metricView.value)(bccAccountTotals.total[filtersConfig.metricView.value])}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      </Panel>
    </Container>
  );
}
