import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import moment from 'moment';
import GridTools from './GridTools';
import DateRangePicker from '../../../../../shared/components/widgets/DateRangePicker';
import FilterSelect from '../../../../../shared/components/filters/FilterSelect';

const GridInfoShape = {
  columnDefs: PropTypes.instanceOf(Array),
  defaultColDef: PropTypes.instanceOf(Object),
  autoGroupColumnDef: PropTypes.instanceOf(Object),
  dataSource: PropTypes.instanceOf(Object),
  paginationPageSize: PropTypes.number,
};

export default function PpcReportGrid({
  gridInfo, onDateChange, exportPrefix, loading, setLoading, pivot, filtersConfig, reportStartDate, reportEndDate,
}) {
  const { theme } = useSelector(state => state);
  const [api, setApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const {
    columnDefs,
    defaultColDef,
    dataSource,
    paginationPageSize,
  } = gridInfo;

  const tableHeight = '75vh';
  let useTheme = 'ag-theme-balham';
  if (!theme.className.endsWith('light')) {
    useTheme = 'ag-theme-balham-dark';
  }

  function onGridReady(params) {
    setApi(params.api);
    setColumnApi(params.columnApi);
    params.api.setServerSideDatasource(dataSource);
  }

  useEffect(() => {
    if (api && dataSource) {
      setLoading(true);
      api.setServerSideDatasource(dataSource);
    }
  }, [pivot.option, filtersConfig, reportStartDate, reportEndDate]);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col xl={3}>
              <DateRangePicker
                // label="Date Range:"
                startDate={moment(reportStartDate).toDate()}
                endDate={moment(reportEndDate).toDate()}
                onDateChange={onDateChange}
                loading={localLoading || loading}
              />
            </Col>
            <Col xl={3}>
              {pivot.options && (
                <FilterSelect
                  onChange={pivot.set}
                  // label="Pivot Options:"
                  name="pivotOptions"
                  options={pivot.options}
                  selected={pivot.option}
                  disable={localLoading || loading}
                />
              )}
            </Col>
            <Col xl={6}>
              <GridTools
                options={{
                  gridApi: api,
                  gridColumnApi: columnApi,
                  startDate: reportStartDate,
                  endDate: reportEndDate,
                  exportPrefix,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={useTheme} style={{ height: tableHeight, width: '100%', paddingTop: '10px' }}>
                <AgGridReact
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={params => onGridReady(params)}
                  rowModelType="serverSide"
                  suppressServerSideInfiniteScroll={false}
                  // editType="fullRow"
                  rowSelection="multiple"
                  sideBar={false}
                  paginationPageSize={paginationPageSize}
                  cacheBlockSize={paginationPageSize}
                  serverSideInfiniteScroll
                  suppressAggFuncInHeader
                  enableRangeSelection
                  pagination
                  enableCharts
                  onModelUpdated={(params) => { setLocalLoading(!params.animate); setLoading(!params.animate); }}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}

PpcReportGrid.propTypes = {
  gridInfo: PropTypes.shape(GridInfoShape).isRequired,
  onDateChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};
