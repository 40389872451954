import React from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './FilterSelect';

export const BccOptions = {
  ALL: {
    label: 'ALL',
    value: '',
  },
  BRAND: {
    label: 'Brand',
    value: 'Brand',
  },
  CATEGORY: {
    label: 'Category',
    value: 'Category',
  },
  COMPETITOR: {
    label: 'Competitor',
    value: 'Competitor',
  },
  * [Symbol.iterator]() {
    yield BccOptions.ALL;
    yield BccOptions.BRAND;
    yield BccOptions.CATEGORY;
    yield BccOptions.COMPETITOR;
  },
};

export default function BccFilter({
  value, onChange, disable,
}) {
  return (
    <FilterSelect
      label="BCC Filter:"
      onChange={option => onChange(option)}
      name="bccOption"
      options={[...BccOptions]}
      selected={value}
      disable={disable}
    />
  );
}

BccFilter.defaultProps = {
  value: BccOptions.ALL,
  disable: false,
};

BccFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([...BccOptions]),
  disable: PropTypes.bool,
};
