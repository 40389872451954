import React from 'react';
import PropTypes from 'prop-types';
import NewFilterSelect from './FilterSelect';

export const DateViewOptions = {
  DAY: {
    label: 'Day',
    value: 'day',
  },
  WEEK: {
    label: 'Week',
    value: 'week',
  },
  MONTH: {
    label: 'Month',
    value: 'month',
  },
  * [Symbol.iterator]() {
    yield DateViewOptions.DAY;
    yield DateViewOptions.WEEK;
    yield DateViewOptions.MONTH;
  },
};

export default function DateViewFilter({
  value, onChange, disable,
}) {
  return (
    <NewFilterSelect
      label="Date View:"
      onChange={option => onChange(option)}
      name="dateViewOption"
      options={[...DateViewOptions]}
      selected={value}
      disable={disable}
    />
  );
}

DateViewFilter.defaultProps = {
  value: DateViewOptions.DAY,
  disable: false,
};

DateViewFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([...DateViewOptions]),
  disable: PropTypes.bool,
};
