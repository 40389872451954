import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Panel from '../../../../../shared/components/Panel';
import ChartBuilder from '../../../../../shared/components/chart/ChartBuilder';
import { MetricOptions } from '../../../../../shared/components/filters/SalesMetricFilter';
import { columnDefs } from './columns';

export default function CompetitionTreemap({
  title, loading, data, metric,
}) {
  const theme = useSelector(store => store.theme);

  const divStart = '<div style="padding-bottom: 10px; padding: 5px; background-color: white;">';
  const divEnd = '</div>';

  const chartOptions = {
    data: [{ title: metric.label, children: data }],
    theme: theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark',
    padding: {
      top: 40,
      bottom: 40,
    },
    title: {
      text: metric.label,
    },
    series: [
      {
        type: 'treemap',
        labelKey: 'competitor',
        sizeKey: metric.value,
        sizeName: metric.label,
        colorKey: metric.value,
        tooltip: {
          renderer: (params) => {
            const column = columnDefs.filter(item => item.field === metric.value)[0];
            return `${divStart}${params.datum.competitor} : ${column.valueFormatter({ value: params.datum[metric.value] })}${divEnd}`;
          },
        },
      },
    ],
  };

  return (
    <Panel
      md={12}
      title={title}
      subhead={metric.label}
      showRefreshButton={false}
    >
      <Row>
        <Col lg={12}>
          <div style={{ textAlign: 'left' }}>
            <ChartBuilder chartOptions={chartOptions} parentRefresh={loading} chartHeight="700px" />
          </div>
        </Col>
      </Row>
    </Panel>
  );
}

CompetitionTreemap.defaultProps = {
  loading: false,
  data: null,
  metric: MetricOptions.SALES,
};

CompetitionTreemap.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
  metric: PropTypes.oneOf([...MetricOptions]),
};
