import React from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './FilterSelect';

export const DataSources = {
  ALL: {
    label: 'ASINs and Search Terms',
    value: '',
  },
  ASINS: {
    label: 'ASINs',
    value: 'targets',
  },
  SEARCH_TERMS: {
    label: 'Search Terms',
    value: 'searchTerms',
  },
  * [Symbol.iterator]() {
    yield DataSources.ALL;
    yield DataSources.ASINS;
    yield DataSources.SEARCH_TERMS;
  },
};

export default function DataSourceFilter({
  value, onChange, disable,
}) {
  return (
    <FilterSelect
      label="Data Source:"
      onChange={option => onChange(option)}
      name="sourceOption"
      options={[...DataSources]}
      selected={value}
      disable={disable}
    />
  );
}

DataSourceFilter.defaultProps = {
  value: DataSources.ALL,
  disable: false,
};

DataSourceFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([...DataSources]),
  disable: PropTypes.bool,
};
