import React, { PureComponent } from 'react';
import {
  Container, Col, Row, Table,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, // Line,
} from 'recharts';
import Panel from '../../../../../shared/components/Panel';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import { SellerSelect, User, Theme } from '../../../../../shared/prop-types/MainProps';
import {
  formatNumberWhole, currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberOnePlace,
} from '../../../../../shared/components/table/functions';
import style from '../../../../../shared/components/themeSupport';
import { history } from '../../../../../helpers';
import config from '../../../../../config';
import FilterSelect, { DEFAULT_ALL_FILTER_VALUE } from '../../../../../shared/components/filters/FilterSelectDeprecated';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class PpcOverTimeBrandsPlus extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      reportStartDate: moment().subtract(34, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      initalLoad: false,
      metricOption: { label: 'Sales 14D Attribution', value: 'sales14d' },
      metricOptions: [{ label: 'Impressions', value: 'impressions' }, { label: 'Clicks', value: 'clicks' }, { label: 'Orders 1D Attribution', value: 'orders1d' }, { label: 'Orders 7D Attribution', value: 'orders7d' }, { label: 'Orders 14D Attribution', value: 'orders14d' }, { label: 'Orders 30D Attribution', value: 'orders30d' }, { label: 'Spend', value: 'spend' }, { label: 'Sales 1D Attribution', value: 'sales1d' }, { label: 'Sales 7D Attribution', value: 'sales7d' }, { label: 'Sales 14D Attribution', value: 'sales14d' }, { label: 'Sales 30D Attribution', value: 'sales30d' }, { label: 'ACoS', value: 'acos' }, { label: 'ROAS', value: 'roas' }, { label: 'CVR', value: 'cvr' }],
      dateViewOption: { label: 'Day', value: 'day' },
      dateViewOptions: [{ label: 'Day', value: 'day' }, { label: 'Week', value: 'week' }, { label: 'Month', value: 'month' }],
      brandList: [],
      dateTotalsData: [],
      brandsMap: new Map(),
      brandOption: DEFAULT_ALL_FILTER_VALUE,
      brandOptions: [],
      categoryOption: DEFAULT_ALL_FILTER_VALUE,
      categoryOptions: [],
      portfolioOption: DEFAULT_ALL_FILTER_VALUE,
      portfolioOptions: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, dateViewOption, brandOption, categoryOption, portfolioOption,
    } = this.state;

    const brandOverTimeWithFiltersUrl = `${apiUrl}/customers/brand-ot-with-filters-plus?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}&dataGroup=${dateViewOption.value}&brandFilter=${brandOption.value}&categoryFilter=${categoryOption.value}&portfolioFilter=${portfolioOption.value}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
    });

    fetch(brandOverTimeWithFiltersUrl, requestOptions)
      .then((results) => {
        if (results.status === 401) {
          history.push('/logout');
        }
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const brandsMap = new Map();
        const brandsSortMap = new Map();
        const dayTotalsMap = new Map();

        data.forEach((item) => {
          // Brand Totals
          const brandSort = brandsSortMap.get(item.brand);
          if (brandSort) {
            brandsSortMap.set(item.brand, brandSort + item.sales14d);
          } else {
            brandsSortMap.set(item.brand, item.sales14d);
          }

          const brandData = brandsMap.get(item.brand);
          if (brandData) {
            brandData.push(item);
            brandsMap.set(item.brand, brandData);
          } else {
            brandsMap.set(item.brand, [item]);
          }

          const dateTotals = dayTotalsMap.get(item.itemDate);
          if (dateTotals) {
            Object.keys(item).forEach((key) => {
              if (!['itemDate', 'brand'].includes(key)) {
                dateTotals[key] += item[key];
              }
            });
            delete dateTotals.brand; // not used with this data
            dateTotals.acos = (dateTotals.sales14d > 0) ? (dateTotals.spend / dateTotals.sales14d) : 0;
            dateTotals.roas = (dateTotals.spend > 0) ? (dateTotals.sales14d / dateTotals.spend) : 0;
            dateTotals.cvr = (dateTotals.clicks > 0) ? (dateTotals.orders14d / dateTotals.clicks) : 0;
            dayTotalsMap.set(item.itemDate, dateTotals);
          } else {
            const newDateTotals = {};
            Object.keys(item).forEach((key) => {
              newDateTotals[key] = item[key];
            });
            delete newDateTotals.brand; // not used with this data
            dayTotalsMap.set(item.itemDate, newDateTotals);
          }
        });

        const brandList = [...brandsSortMap.entries()].sort((a, b) => b[1] - a[1]).map(x => x[0]);
        const dayTotals = Array.from(dayTotalsMap.values());

        this.setState({
          initalLoad: false,
          brandList,
          brandsMap,
          dateTotalsData: dayTotals,
        });
      })
      .catch(() => {
        this.setState({
          initalLoad: false,
        });
      });
  };

  handleChange = (option, select) => {
    this.setState({
      [select.name]: option,
    }, () => {
      if (select.name !== 'metricOption') {
        const { initalLoad } = this.state;
        if (!initalLoad) { // Don't trigger if we already have a reqeust in flight.
          this.getData();
        }
      }
    });
  }

  functionFormatter = (itemType) => {
    let tickFormatter = number => formatNumberWhole(number);

    if (['acos', 'cvr'].includes(itemType)) {
      tickFormatter = percent => formatDecimalToFixedTwoPercent({ value: percent });
    }
    if (['sales1d', 'sales7d', 'sales14d', 'sales30d', 'spend'].includes(itemType)) {
      tickFormatter = dollar => currencyFormatter({ value: dollar });
    }
    if (['roas'].includes(itemType)) {
      tickFormatter = number => formatNumberOnePlace(number);
    }
    return tickFormatter;
  }

  checkRow = (brandData, dateData, metric) => {
    if (brandData.length === dateData.length) {
      return brandData;
    }
    const fixRow = [];
    const brandMap = new Map();
    brandData.forEach((brand) => {
      brandMap.set(brand.itemDate, brand);
    });

    dateData.forEach((day) => {
      fixRow.push(brandMap.get(day.itemDate) ? brandMap.get(day.itemDate) : { [metric]: 0 });
    });
    return fixRow;
  }

  render() {
    const {
      initalLoad, reportStartDate, reportEndDate, categoryOptions, categoryOption, brandOptions, brandOption,
      dateViewOption, dateViewOptions, metricOption, metricOptions, brandList, dateTotalsData, brandsMap, portfolioOption, portfolioOptions,
    } = this.state;

    const { theme, sellerSelect, user } = this.props;

    const portfolioUrl = `${apiUrl}/customers/portfolios?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}`;
    const portfolioFields = {
      id: 'portfolioId',
      name: 'name',
    };

    const urlPath = (sellerSelect.type === 2) ? '/vendors/asin-master' : '/sellers/sku-master';
    const skuMasterUrl = `${apiUrl}${urlPath}?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}`;
    const brandFields = {
      id: 'brand',
      name: 'brand',
    };
    const categoryFields = {
      id: 'segment_one',
      name: 'segment_one',
    };

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Panel
          lg={12}
          md={12}
          title="Filters"
          subhead="NOTE: Filters and View only use data in the date range selected.  If your date range doesn't match your view, you could be seeing partial data on the ends of your view."
          parentRefresh={initalLoad}
        >
          <Row>
            <Col lg={2}>
              <FilterSelect
                handleSelectChange={this.handleChange}
                user={user}
                theme={theme}
                label="Metric to graph:"
                name="metricOption"
                options={metricOptions}
                selectedOption={metricOption}
                disable={initalLoad}
              />
            </Col>
            <Col lg={3}>
              <FilterSelect
                handleSelectChange={this.handleChange}
                user={user}
                theme={theme}
                label="Portfolio Filter:"
                name="portfolioOption"
                filterUrl={portfolioUrl}
                filterFields={portfolioFields}
                options={portfolioOptions}
                selectedOption={portfolioOption}
                disable={initalLoad}
              />
            </Col>
            <Col lg={3}>
              <FilterSelect
                handleSelectChange={this.handleChange}
                user={user}
                theme={theme}
                label="Brand Filter:"
                name="brandOption"
                filterUrl={skuMasterUrl}
                filterFields={brandFields}
                options={brandOptions}
                selectedOption={brandOption}
                disable={initalLoad}
              />
            </Col>
            <Col lg={3}>
              <FilterSelect
                handleSelectChange={this.handleChange}
                user={user}
                theme={theme}
                label="Category Filter:"
                name="categoryOption"
                filterUrl={skuMasterUrl}
                filterFields={categoryFields}
                options={categoryOptions}
                selectedOption={categoryOption}
                disable={initalLoad}
              />
            </Col>
            <Col lg={1}>
              <FilterSelect
                handleSelectChange={this.handleChange}
                user={user}
                theme={theme}
                label="Date View:"
                name="dateViewOption"
                options={dateViewOptions}
                selectedOption={dateViewOption}
                disable={initalLoad}
              />
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title=""
          subhead=""
          parentRefresh={initalLoad}
        >
          <Row>
            <Col md={12}>
              <ResponsiveContainer height={400} className="dashboard__area">
                <ComposedChart
                  width={600}
                  height={400}
                  data={dateTotalsData}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid stroke={style(theme).colorText} />
                  <XAxis dataKey="itemDate" stroke={style(theme).colorText} />
                  <YAxis yAxisId="left" tickFormatter={this.functionFormatter(metricOption.value)} stroke={style(theme).colorText} />
                  <YAxis yAxisId="right" orientation="right" tickFormatter={number => number} domain={[0, dataMax => (dataMax * 1.5)]} stroke={style(theme).colorText} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey={metricOption.value} name={metricOption.label} formatter={this.functionFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#03c03c" />
                  {/* <Line type="monotone" dataKey="salesRankAvg" name="Sales Rank Avg" yAxisId="right" stroke="#FF9966" /> */}
                </ComposedChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title={metricOption.label}
          subhead=""
          parentRefresh={initalLoad}
        >
          <div style={{
            marginLeft: '200px',
          }}
          >
            <Table responsive striped className="dashboard__table-orders" id="dataOverTime">
              <thead>
                <tr>
                  <th className="sticky-th-wide" />
                  {dateTotalsData.map(item => (
                    <th key={item.itemDate}>{item.itemDate}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {brandList.map((brand, index) => (
                  <tr key={brand}>
                    <td key={brand} className={(index % 2) ? 'sticky-td-wide' : 'sticky-td-color-wide'}>{brand}&nbsp;</td>
                    {this.checkRow(brandsMap.get(brand), dateTotalsData, metricOption.value).map((item => <td key={item.itemDate}>{this.functionFormatter(metricOption.value)(item[metricOption.value])}</td>))}
                  </tr>
                ))}
                <tr>
                  <td className={(brandList.length % 2) ? 'sticky-td-wide' : 'sticky-td-color-wide'}>TOTAL</td>
                  {dateTotalsData.map(item => <td key={item.itemDate}>{this.functionFormatter(metricOption.value)(item[metricOption.value])}</td>)}
                </tr>
              </tbody>
            </Table>
          </div>
        </Panel>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(PpcOverTimeBrandsPlus);
