import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import LandingPageEditModal from './form/LandingPageEditModal';
import config from '../../../../../config';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import { getExportPrefix } from '../../../../../shared/components/table/functions';

export default function BudgetGroupLandingPages() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const theme = useSelector(store => store.theme);
  const [showEditModal, setShowEditModal] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [editRowData, setEditRowData] = useState({});
  const [agGrid, setAgGrid] = useState(null);
  // function jsonCellRenderer(params) {
  // // Hold  cellRenderer: (params) => <pre style={{ fontFamily: 'monospace', margin: 0, padding: 0, whiteSpace: 'pre-wrap' }}>${JSON.stringify(JSON.parse(params.value), null, 2)}</pre>
  //   return `<pre style="font-family: monospace; margin: 0; padding: 0; white-space: pre-wrap;">${JSON.stringify(JSON.parse(params.value), null, 2)}</pre>`;
  // }

  const changeShowEditModal = (data) => {
    setEditRowData(data);
    setShowEditModal(!showEditModal);
  };

  const columnDefs = [
    {
      field: 'editEvents',
      headerName: 'Events',
      editable: false,
      cellRenderer: (params) => {
        if (params.node?.footer) {
          return '';
        }
        return <button type="submit" onClick={() => changeShowEditModal(params.data)}>Edit</button>;
      },
    },
    {
      field: 'landingPageId', headerName: 'Landing Page ID',
    },
    {
      field: 'accountId', headerName: 'Account ID',
    },
    {
      field: 'budgetGroupName', headerName: 'Budget Group Name',
    },
    {
      field: 'budgetAmount', headerName: 'Budget Amount',
    },
    {
      field: 'startDate', headerName: 'Start Date',
    },
    {
      field: 'endDate', headerName: 'EndDate Group',
    },
    {
      field: 'url', headerName: 'Url',
    },
    {
      field: 'goal', headerName: 'Goal',
    },
    {
      field: 'funnelConfigJson', headerName: 'Funnel Config',
    },
    {
      field: 'audienceDtlsJson', headerName: 'AudienceDtls',
    },
    {
      field: 'creativeState', headerName: 'Creative State',
    },
    {
      field: 'eventState', headerName: 'EventState',
    },
    {
      field: 'state', headerName: 'State',
    },
    {
      field: 'UupdateTs', headerName: 'Update TS',
    },
    {
      field: 'createTs', headerName: 'Create TS',
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const showDatePicker = false;
  const showExportOptions = true;
  const apiEndPoint = `${config.serverUrl}/management/budgetGroupLandingPages`;

  const exportPrefix = getExportPrefix('budget-group-landing-pages', selectedSeller, true);

  const parentAccess = (agGridPointer) => {
    setAgGrid(agGridPointer);
  }

  useEffect(() => {
    if (agGrid) {
      agGrid.refreshData();
    }
  }, [forceRefresh]);

  return (
    <Container>
      <LandingPageEditModal
        changeShowEditModal={changeShowEditModal}
        currentEditRow={editRowData}
        showEditModal={showEditModal}
        theme={theme}
        setForceRefresh={setForceRefresh}
        setShowEditModal={setShowEditModal}
      />
      <Row>
        <TableBuilder
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          apiEndPoint={apiEndPoint}
          showDatePicker={showDatePicker}
          showExportOptions={showExportOptions}
          exportPrefix={exportPrefix}
          parentAccess={parentAccess}
        />
      </Row>
    </Container>
  );
}
