import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import moment from 'moment';
import { useDashboardContext } from './components/DashboardContext';
import useVendorData from './components/useVendorData';
import CardPanel from '../../../shared/components/widgets/CardPanel';
import DateRangePicker from '../../../shared/components/widgets/DateRangePicker';
import BrandSelect from './components/BrandsSelect';
import ExecutiveView from './components/ExecutiveView';
import DateViewFilter from '../../../shared/components/filters/DateViewFilter';
import { ToggleSlide } from '../../../shared/components/widgets/ToggleSlide';
import { getPlatform } from '../../../shared/components/domainSupport';
import Spend from './components/Spend';
import Sales from './components/Sales';
import PPCAcos from './components/PPCAcos';
import OrderedRevenue from './components/OrderedRevenue';
import TotalAcos from './components/TotalACoS';
import AverageSalesRank from './components/AverageSalesRank';
import SalesGraph from './components/SalesGraph';
import ProductPerformance from './components/ProductPerformance';
import SpendGraph from './components/SpendGraph';

export default function VendorDashboard() {
  const { state, actions } = useDashboardContext();
  const {
    accountSelect, startSelectionDate, endSelectionDate, dateView, brand, runOnChnage,
  } = state;
  const {
    setDates, setDateView, setBrandFilter, setRunOnChnage,
  } = actions;
  const platfromSpec = getPlatform();

  const init = [
    false,
    {
      data: [],
      pieData: [],
      topAsinsAcos: null,
      topAsinsRank: null,
      rankData: null,
    },
    '',
  ];

  const [loading, results] = runOnChnage ? useVendorData(accountSelect, startSelectionDate, endSelectionDate, dateView, brand) : init;

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={12}>
          <CardPanel
            showButtons={false}
          >
            <Row>
              <Col xs={12} lg={4} xl={3}>
                <DateRangePicker
                  label="Select Dates:"
                  startDate={startSelectionDate.toDate()}
                  endDate={endSelectionDate.toDate()}
                  onDateChange={(start, end) => setDates(moment(start), moment(end))}
                  loading={loading}
                />
              </Col>
              <Col xs={12} lg={3} xl={3}>
                <BrandSelect
                  label="Brand (Only SP and SD data when applied):"
                  brandSelected={brand}
                  onChange={newBrand => setBrandFilter(newBrand)}
                  accountId={accountSelect.value}
                  loading={loading}
                />
              </Col>
              <Col xs={12} lg={4} xl={3}>
                <DateViewFilter value={dateView} onChange={view => setDateView(view)} disable={loading} />
              </Col>
              <Col xs={12} lg={3} xl={3}>
                <ToggleSlide
                  name="Run on change"
                  setFunction={() => setRunOnChnage(!runOnChnage)}
                  currentState={runOnChnage}
                  bgColor={platfromSpec.colorPrimary}
                  disable={loading}
                />
              </Col>
            </Row>
          </CardPanel>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4}>
          <AverageSalesRank
            data={results && results.rankData}
            loading={loading}
            dateView={dateView}
            lastDay={endSelectionDate}
          />
        </Col>
        <Col md={6} lg={4}>
          <OrderedRevenue
            data={results && results.data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
          />
        </Col>
        <Col md={6} lg={4}>
          <TotalAcos
            data={results && results.data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
          />
        </Col>
        <Col md={6} lg={4}>
          <Spend
            data={results && results.data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
          />
        </Col>
        <Col md={6} lg={4}>
          <Sales
            data={results && results.data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
          />
        </Col>
        <Col md={6} lg={4}>
          <PPCAcos
            data={results && results.data}
            loading={loading}
            dateView={dateView}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SalesGraph
            title="PPC Sales"
            subhead="By Campaign Type"
            loading={loading}
            data={results && results.pieData}
            account={accountSelect}
          />
        </Col>
        <Col md={6}>
          <SpendGraph
            title="PPC Spend"
            subhead="By Campaign Type"
            loading={loading}
            data={results && results.pieData}
            account={accountSelect}
          />
        </Col>
        <Col md={6}>
          <ProductPerformance
            loading={loading}
            title="Best Performers -  ACoS"
            subtitle={'By Average ACoS for Date Range'}
            data={results && results.topAsinsAcos}
            field={'acos'}
          />
        </Col>
        <Col md={6}>
          <ProductPerformance
            loading={loading}
            title="Best Performers - Rank"
            subtitle={'By Average Rank for Date Range'}
            data={results && results.topAsinsRank}
            field={'rank'}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ExecutiveView
            title="Ad Sales vs Ad Spend"
            loading={loading}
            account={accountSelect}
            data={results && results.data}
            dateView={dateView}
          />
        </Col>
      </Row>
    </Container>
  );
}
