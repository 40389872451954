import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DateViewOptions } from '../../../../../shared/components/filters/DateViewFilter';

const initialState = {
  startSelectionDate: moment().subtract(34, 'days'),
  endSelectionDate: moment().subtract(4, 'days'),
  dateView: DateViewOptions.WEEK,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setDates':
      return {
        ...state,
        startSelectionDate: action.payload.start,
        endSelectionDate: action.payload.end,
      };
    case 'setDateView':
      return {
        ...state,
        dateView: action.payload,
      };
    default:
      throw new Error();
  }
};

const setSelectionDates = (startDate, endDate) => ({ type: 'setDates', payload: { start: startDate, end: endDate } });
const setDateView = dateView => ({ type: 'setDateView', payload: dateView });

const actions = dispatch => ({
  setDates: (startDate, endDate) => dispatch(setSelectionDates(startDate, endDate)),
  setDateView: dateView => dispatch(setDateView(dateView)),
});

const PageContext = React.createContext(initialState);

export const PageContextProvider = (props) => {
  const { children } = props;
  const [reducerState, dispatch] = React.useReducer(reducer, initialState);
  const reducerActions = actions(dispatch);
  // eslint-disable-next-line
  const context = {
    state: { ...reducerState },
    actions: { ...reducerActions },
  };

  return (
    <PageContext.Provider value={context}>
      { children }
    </PageContext.Provider>
  );
};

export function usePageContext() {
  const context = React.useContext(PageContext);
  if (context === undefined) {
    throw new Error('usePageContext must be used within a PageContextProvider');
  }
  return context;
}

PageContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
