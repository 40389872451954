import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import config from '../../../config';
import { history } from '../../../helpers';
import Panel from '../Panel';
import FilterSelect from './FilterSelect';
import DateViewFilter, { DateViewOptions } from './DateViewFilter';
import BccFilter, { BccOptions } from './BccFilter';
import DataSetFilter, { DataSets } from './DataSetFilter';
import MetricFilter, { MetricOptions } from './SalesMetricFilter';
import DataSourceFilter, { DataSources } from './DataSourceFilter';
import DateRangePicker from '../widgets/DateRangePicker';

export const defaultFilters = {
  portfolioFilter: '',
  brandFilter: '',
  subcategoryFilter: '',
  budgetGroupFilter: '',
  itemsFilter: '',
  showAdsFilter: '',
  reportingVisibilityFilter: 'ON',
  dateView: 'day',
  bccFilter: '',
  dataSetFilter: '',
  metricView: MetricOptions.SALES,
  dataSourceOption: DataSources.ALL,
  dateRange: { start: moment().subtract(9, 'days'), end: moment().subtract(2, 'days') },
};

export default function ReportFilters({ filterCsv, setFiltersConfig, itemName, loading, setLoading, defaultDateRange, title, subhead }) {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const loggedIn = useSelector(state => state.authentication.loggedIn);

  const showAdsOptions = [ // AKA "Includes"
    { label: 'All ADs', value: '' },
    { label: 'Limitless ADs', value: 'limitless' },
  ];

  const reportingVisibilityOptions = [
    { label: 'All', value: '' },
    { label: 'ON Only', value: 'ON' },
  ];

  const defaultOptions = [{ label: 'All', value: '' }];

  const [portfolioOption, setPortfolioOption] = useState(defaultOptions[0]);
  const [portfolioOptions, setPortfolioOptions] = useState(defaultOptions);
  const [brandOption, setBrandOption] = useState(defaultOptions[0]);
  const [brandOptions, setBrandOptions] = useState(defaultOptions);
  const [subcategoryOption, setSubcategoryOption] = useState(defaultOptions[0]);
  const [subcategoryOptions, setSubcategoryOptions] = useState(defaultOptions);
  const [budgetGroupOption, setBudgetGroupOption] = useState(defaultOptions[0]);
  const [budgetGroupOptions, setBudgetGroupOptions] = useState(defaultOptions);
  const [itemOption, setItemOption] = useState([defaultOptions[0]]);
  const [itemOptions, setItemOptions] = useState(defaultOptions);
  const [showAdsOption, setShowAdsOption] = useState(showAdsOptions[0]);
  const [reportingVisibilityOption, setReportingVisibilityOption] = useState(reportingVisibilityOptions[1]);
  const [dateViewOption, setDateViewOption] = useState(DateViewOptions.DAY);
  const [bccOption, setBccOption] = useState(BccOptions.ALL);
  const [dataSetOption, setDataSetOption] = useState(DataSets.ALL);
  const [metricOption, setMetricOption] = useState(MetricOptions.SALES);
  const [dataSourceOption, setDataSourceOption] = useState(DataSources.ALL);
  const [dateRange, setDateRange] = useState(defaultDateRange || defaultFilters.dateRange);

  const resetFilters = () => {
    setPortfolioOption(defaultOptions[0]);
    setBrandOption(defaultOptions[0]);
    setSubcategoryOption(defaultOptions[0]);
    setBudgetGroupOption(defaultOptions[0]);
    setItemOption([defaultOptions[0]]);
    setShowAdsOption(showAdsOptions[0]);
    setReportingVisibilityOption(reportingVisibilityOptions[1]);
    setDateViewOption(DateViewOptions.DAY);
    setBccOption(BccOptions.ALL);
    setDataSetOption(DataSets.ALL);
    // setMetricOption(MetricOptions.SALES); // Isn't needed as it doesn't require a reload
    setDataSourceOption(DataSources.ALL);
    // setDateRange(defaultDateRange); // Can't set becuase the Date Picker doesn't update
  }

  useEffect(() => {
    setLoading(true);

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const getUrl = `${config.serverUrl}/amazon/filters?accountId=${selectedSeller.value}&filterCsv=${filterCsv}`;

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    fetch(getUrl, requestOptions)
      .then((results) => {
        if (results.status === 401) {
          history.push('/logout');
        }
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        setPortfolioOptions([...defaultOptions, ...data.portfolioFilter]);
        setBrandOptions([...defaultOptions, ...data.brandFilter]);
        setSubcategoryOptions([...defaultOptions, ...data.subcategoryFilter]);
        setBudgetGroupOptions([...defaultOptions, ...data.budgetGroupFilter]);
        setItemOptions(data.itemsFilter);
        // setDateViewOptions([{ label: 'All', value: '' }, ...data.dateViewFilter]);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
  }, [selectedSeller.value]);

  useEffect(() => {
    if (!itemOption) {
      setItemOption(defaultOptions);
    }
    if (itemOption && itemOption.length > 1 && itemOption.some(item => JSON.stringify(item) === JSON.stringify(defaultOptions[0]))) {
      setItemOption(itemOption.filter(item => item.value !== ''));
    }
  }, [itemOption]);

  useEffect(() => {
    setFiltersConfig({
      portfolioFilter: portfolioOption.value,
      brandFilter: brandOption.value,
      subcategoryFilter: subcategoryOption.value,
      budgetGroupFilter: budgetGroupOption.value,
      itemsFilter: itemOption?.map(item => item.value).join(','),
      showAdsFilter: showAdsOption.value,
      reportingVisibilityFilter: reportingVisibilityOption.value,
      dateView: dateViewOption.value,
      bccFilter: bccOption.value,
      dataSetFilter: dataSetOption.value,
      metricView: metricOption,
      dataSourceOption,
      dateRange,
    });
  }, [portfolioOption, brandOption, subcategoryOption, budgetGroupOption, itemOption, showAdsOption, reportingVisibilityOption, dateViewOption, bccOption, dataSetOption, metricOption, dataSourceOption, dateRange]);

  const filterItems = filterCsv.split(',');

  return (
    <Panel
      lg={12}
      md={12}
      title={title}
      subhead={subhead}
      getData={resetFilters}
    >
      <Row>
        {filterItems.includes('dates') && (
          <Col md={4} xl={2}>
            <DateRangePicker
              label="Select Dates:"
              startDate={dateRange.start.toDate()}
              endDate={dateRange.end.toDate()}
              onDateChange={(start, end) => setDateRange({ start: moment(start), end: moment(end) })}
              loading={loading}
            />
          </Col>
        )}
        {filterItems.includes('metric') && (
          <Col md={4} xl={2}>
            <MetricFilter
              onChange={setMetricOption}
              value={metricOption}
              disable={loading}
            />
          </Col>
        )}
        {filterItems.includes('dataSource') && (
          <Col md={4} xl={2}>
            <DataSourceFilter
              onChange={setDataSourceOption}
              initialValue={dataSourceOption}
              disable={loading}
            />
          </Col>
        )}
        {loggedIn && (user.access === 'admin' || user.access === 'agency') && filterItems.includes('budgetGroup') && (
          <Col md={4} xl={2}>
            <FilterSelect
              onChange={setBudgetGroupOption}
              label={(<span style={{ position: 'relative', display: 'flex' }}>Budget Group Filter:<span className="sidebar__category-agency" /></span>)}
              name="budgetGroup"
              options={budgetGroupOptions}
              selected={budgetGroupOption}
              disable={loading}
            />
          </Col>
        )}
        {filterItems.includes('brand') && (
          <Col md={4} xl={2}>
            <FilterSelect
              onChange={setBrandOption}
              label="Brand Filter:"
              name="brand"
              options={brandOptions}
              selected={brandOption}
              disable={loading}
            />
          </Col>
        )}
        {filterItems.includes('subcategory') && (
          <Col md={4} xl={2}>
            <FilterSelect
              onChange={setSubcategoryOption}
              label="Subcategory Filter:"
              name="subcategory"
              options={subcategoryOptions}
              selected={subcategoryOption}
              disable={loading}
            />
          </Col>
        )}
        {filterItems.includes('items') && (
          <Col md={4} xl={2}>
            <FilterSelect
              onChange={setItemOption}
              label={`${itemName} Filter:`}
              name="items"
              options={itemOptions}
              selected={itemOption}
              disable={loading}
              multi
            />
          </Col>
        )}
        {filterItems.includes('portfolio') && (
          <Col md={4} xl={2}>
            <FilterSelect
              onChange={setPortfolioOption}
              label="Portfolio Filter:"
              name="portfolio"
              options={portfolioOptions}
              selected={portfolioOption}
              disable={loading}
            />
          </Col>
        )}
        {filterItems.includes('bcc') && (
          <Col xl={2}>
            <BccFilter
              value={bccOption}
              onChange={setBccOption}
              disable={loading}
            />
          </Col>
        )}
        {filterItems.includes('dataSet') && (
          <Col xl={2}>
            <DataSetFilter
              value={dataSetOption}
              onChange={setDataSetOption}
              disable={loading}
            />
          </Col>
        )}
        {filterItems.includes('dateView') && (
          <Col xl={2}>
            <DateViewFilter
              value={dateViewOption}
              onChange={setDateViewOption}
              disable={loading}
            />
          </Col>
        )}
        {loggedIn && user.access === 'admin' && (
          <Col md={4} xl={2}>
            <FilterSelect
              onChange={setShowAdsOption}
              label={(<span style={{ position: 'relative', display: 'flex' }}>Show Ads:<span className="sidebar__category-admin" /></span>)}
              name="showAdsOption"
              options={showAdsOptions}
              selected={showAdsOption}
              disable={loading}
            />
          </Col>
        )}
        {loggedIn && user.access === 'admin' && (
          <Col md={4} xl={2}>
            <FilterSelect
              onChange={setReportingVisibilityOption}
              label={(<span style={{ position: 'relative', display: 'flex' }}>Reporting Visibility:<span className="sidebar__category-admin" /></span>)}
              name="reportingVisibilityOption"
              options={reportingVisibilityOptions}
              selected={reportingVisibilityOption}
              disable={loading}
            />
          </Col>
        )}
      </Row>
    </Panel>
  );
}

ReportFilters.propTypes = {
  setFiltersConfig: PropTypes.func.isRequired,
  filterCsv: PropTypes.string,
  itemName: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  title: PropTypes.string,
  subhead: PropTypes.string,
};

ReportFilters.defaultProps = {
  filterCsv: 'portfolio,brand,subcategory,budgetGroup,showAds,items',
  itemName: 'Item',
  loading: false,
  setLoading: () => {},
  title: 'Filters',
  subhead: '',
};
