import {
  useState, useEffect,
} from 'react';
import {
  when,
} from '../../../../../shared/functions';
import { useFetchReturnsArray as useFetch } from '../../../../../shared/components/hooks/useFetch';
import config from '../../../../../config';

function useAccountAmcInstance(account, forceRefresh) {
  const url = (account && account.value) ? `${config.serverUrl}/accounts/amc/instance?accountId=${account.value}&refreshTs=${forceRefresh}` : undefined;
  return useFetch(url);
}

function useAccount(account, forceRefresh) {
  const url = (account && account.value) ? `${config.serverUrl}/accounts/${account.value}&refreshTs=${forceRefresh}` : undefined;
  return useFetch(url);
}

function useAdvertiserUpdate(account, forceRefresh) {
  const url = (account && account.value) ? `${config.serverUrl}/accounts/amc/instance/advertisers/updates?accountId=${account.value}&refreshTs=${forceRefresh}` : undefined;
  return useFetch(url);
}

export default function useData(
  accountSelect,
  forceRefresh,
) {
  const [instanceLoading, instanceResults, instanceError] = useAccountAmcInstance(accountSelect, forceRefresh);
  const [accountLoading, accountResults, accountError] = useAccount(accountSelect, forceRefresh);
  const [advertiserUpdateLoading, advertiserUpdateResults, advertiserUpdateError] = useAdvertiserUpdate(accountSelect, forceRefresh);
  const loading = instanceLoading || accountLoading || advertiserUpdateLoading;
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!loading) {
      setResults({
        instance: instanceResults,
        account: accountResults[0],
        updates: advertiserUpdateResults,
      });
    }
  }, [instanceResults, accountResults, advertiserUpdateResults]);

  useEffect(() => {
    when(instanceError, () => {
      setError(instanceError);
    });
    when(accountError, () => {
      setError(accountError);
    });
    when(advertiserUpdateError, () => {
      setError(advertiserUpdateError);
    });
  }, [instanceError, accountError, advertiserUpdateError]);

  return [loading, results, error];
}
