import React from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './FilterSelect';

export const DataSetsMulti = {
  SP: {
    label: 'Sponsored Products',
    value: 'sp',
  },
  SB: {
    label: 'Sponsored Brands',
    value: 'sb',
  },
  SBV: {
    label: 'Sponsored Brands Video',
    value: 'sbv',
  },
  SD_CPC: {
    label: 'Sponsored Display - CPC',
    value: 'sd_cpc',
  },
  SD_VCPM: {
    label: 'Sponsored Display - vCPM',
    value: 'sd_vcpm',
  },
  ST: {
    label: 'Sponsored TV',
    value: 'st',
  },
  * [Symbol.iterator]() {
    yield DataSetsMulti.SP;
    yield DataSetsMulti.SB;
    yield DataSetsMulti.SBV;
    yield DataSetsMulti.SD_CPC;
    yield DataSetsMulti.SD_VCPM;
    yield DataSetsMulti.ST;
  },
};

export const DataSets = {
  ALL: {
    label: 'ALL',
    value: '',
  },
  SP: {
    label: 'Sponsored Products',
    value: 'sp',
  },
  SB: {
    label: 'Sponsored Brands',
    value: 'sb',
  },
  SBV: {
    label: 'Sponsored Brands Video',
    value: 'sbv',
  },
  SD: {
    label: 'Sponsored Display',
    value: 'sd',
  },
  ST: {
    label: 'Sponsored TV',
    value: 'st',
  },
  * [Symbol.iterator]() {
    yield DataSets.ALL;
    yield DataSets.SP;
    yield DataSets.SB;
    yield DataSets.SBV;
    yield DataSets.SD;
    yield DataSets.ST;
  },
};

export default function DataSetFilter({
  value, onChange, disable, multi,
}) {
  const dataSetOptions = multi ? DataSetsMulti : DataSets;

  return (
    <FilterSelect
      label="Data Set:"
      onChange={option => onChange(option)}
      name="dataOption"
      options={[...dataSetOptions]}
      selected={value}
      disable={disable}
      multi={multi}
    />
  );
}

DataSetFilter.defaultProps = {
  value: DataSets.ALL,
  disable: false,
  multi: false,
};

DataSetFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([...DataSets]),
  disable: PropTypes.bool,
  multi: PropTypes.bool,
};
