import React from 'react';
import { FiCameraOff } from 'react-icons/fi';
import PropTypes from 'prop-types';
import {
  Badge,
  Table,
} from 'reactstrap';
import CardPanel from '../../../../shared/components/widgets/CardPanel';
import { currencyFormatterDirect, formatDecimalToFixedTwoPercentDirect } from '../../../../shared/components/table/functions';

export default function ProductPerformance({
  loading, title, subtitle, data, fields,
}) {
  function ImageRenderer(itemName, itemImage) {
    const iconStyle = { width: 50, height: 50, color: 'var(--secondary)' };
    const missingImage = <FiCameraOff className="ag-no-image-icon" style={iconStyle} alt="Image not available" />;
    const imageWidth = 50;
    const imageHeight = 50;

    return (
      <div style={{ width: `${imageWidth}px` }}>
        {itemImage && (
          <img onError={() => missingImage} src={itemImage} width={imageWidth} height={imageHeight} title="Product Image" alt={itemName} loading="lazy" />
        )}
        {!itemImage && (
          <FiCameraOff className="ag-no-image-icon" style={iconStyle} alt="Image not available" />
        )}
      </div>
    );
  }

  function formatField(value, fieldType) {
    let returnDisplay = '';
    switch (fieldType) {
      case 'orders':
        returnDisplay = value;
        break;
      case 'clicks':
        returnDisplay = value;
        break;
      case 'impressions':
        returnDisplay = value;
        break;
      case 'spend':
        returnDisplay = currencyFormatterDirect(value);
        break;
      case 'sales':
        returnDisplay = currencyFormatterDirect(value);
        break;
      case 'acos':
        returnDisplay = formatDecimalToFixedTwoPercentDirect(value);
        break;
      case 'roas':
        returnDisplay = value;
        break;
      case 'cvr':
        returnDisplay = value;
        break;
      case 'ctr':
        returnDisplay = value;
        break;
      case 'cpc':
        returnDisplay = value;
        break;
      default:
        returnDisplay = value;
    }

    return returnDisplay;
  }

  return (
    <CardPanel
      title={title}
      subhead={subtitle}
      showButtons={false}
      parentRefresh={loading}
    >
      {!loading && data && (
        <Table responsive striped className="dashboard__table-orders" id="executiveSummary">
          <thead>
            <tr>
              <th>IMAGE</th>
              <th>PRODUCTS</th>
              {fields.map(field => (
                <th>{field.toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item.item_id}>
                <td>{ImageRenderer(item.item_name, item.item_image)}</td>
                <td>{item.item_name}<br /><span><Badge href={item.item_pd_page} target="_blank" color="primary">{item.item_id}</Badge></span></td>
                {fields.map(field => (
                  <td>{formatField(item[field], field)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!data && !loading && (
        <p>Not available...</p>
      )}
    </CardPanel>
  );
}

ProductPerformance.defaultProps = {
  subtitle: 'By Orders for Date Range',
  data: [],
  fields: ['orders', 'acos'],
};

ProductPerformance.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  fields: PropTypes.instanceOf(Array),
};
