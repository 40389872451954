import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import PpcReportMain from './components/PpcReportMain';
import ReportFilters from '../../../../shared/components/filters/ReportFilters';

export default function PpcReportPage() {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const itemName = (sellerSelect.type === 1) ? 'SKU' : 'ASIN';
  const [filtersConfig, setFiltersConfig] = useState({ reportingVisibilityFilter: 'ON' });
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{`PPC ${itemName} Report`}</h3>
        </Col>
      </Row>
      <Row>
        <ReportFilters setFiltersConfig={setFiltersConfig} itemName={itemName} loading={loadingFilters || loadingMain} setLoading={setLoadingFilters} />
      </Row>
      <Row>
        <PpcReportMain filtersConfig={filtersConfig} loading={loadingFilters || loadingMain} setLoading={setLoadingMain} />
      </Row>
    </Container>
  );
}
