import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Row, Col } from 'reactstrap';
import moment from 'moment';

const GridToolsOptionsShape = {
  gridApi: PropTypes.instanceOf(Object),
  gridColumnApi: PropTypes.instanceOf(Object),
  show: PropTypes.bool,
  showExportOptions: PropTypes.bool,
};

function getBooleanValue(cssSelector) {
  return document.querySelector(cssSelector).checked === true;
}

function sizeColumnsToFit(api) {
  api.sizeColumnsToFit();
}

function autoSizeAllColumns(columnApi) {
  columnApi.autoSizeColumns(columnApi.getColumns().map(column => column.colId));
}

function exportAsCSV(api, exportPrefix, startDate, endDate) {
  let fileName = exportPrefix
  if (startDate) {
    const start = moment(startDate).format('YYYYMMDD');
    fileName = `${fileName}-${start}`;
    if (endDate) {
      const end = moment(endDate).format('YYYYMMDD');
      if (end !== start) {
        fileName = `${fileName}-${end}`;
      }
    }
  }
  fileName = `${fileName}.csv`;

  api.exportDataAsCsv({
    onlySelected: getBooleanValue('#onlySelected'),
    fileName,
  });
}

export default function GridTools({
  options,
}) {
  const {
    gridApi,
    gridColumnApi,
    show = true,
    showExportOptions = true,
    startDate,
    endDate,
    exportPrefix,
  } = options;

  useEffect(() => {
    if (gridColumnApi) {
      autoSizeAllColumns(gridColumnApi);
    }
  }, [gridColumnApi]);

  if (show) {
    return (
      <div style={{ display: 'flex', paddingTop: '10px' }}>
        <ButtonToolbar className="form__button-toolbar" style={{ marginLeft: 'auto' }}>
          <Row>
            <Col>
              <button type="submit" className="btn btn-primary" onClick={() => sizeColumnsToFit(gridApi)}><span style={{ whiteSpace: 'nowrap' }}>Size to Fit</span></button>
            </Col>
            <Col>
              <button type="submit" className="btn btn-primary" onClick={() => autoSizeAllColumns(gridColumnApi)}><span style={{ whiteSpace: 'nowrap' }}>Auto-Size All</span></button>
            </Col>
            <Col>
              <button type="submit" className="btn btn-primary" onClick={() => exportAsCSV(gridApi, exportPrefix, startDate, endDate)}><span style={{ whiteSpace: 'nowrap' }}>Export to CSV</span></button>
            </Col>
            {showExportOptions && (
              <Col>
                <p>
                  Export Options: <br />
                  <input type="checkbox" id="onlySelected" /> Only Selected
                </p>
              </Col>
            )}
            {!showExportOptions && (
              <input type="hidden" id="onlySelected" />
            )}
          </Row>
        </ButtonToolbar>
      </div>
    );
  }
  return null;
}

GridTools.propTypes = {
  options: PropTypes.shape(GridToolsOptionsShape).isRequired,
};
