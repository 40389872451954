import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import Panel from '../../../../../shared/components/Panel';
import { currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberDefaultToZero, formatNumberOnePlaceDefaultToZero, getExportPrefix } from '../../../../../shared/components/table/functions';
import { jsonHeader, serverUrl } from '../../../../../config';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import ReportFilters, { defaultFilters } from '../../../../../shared/components/filters/ReportFilters';
import { usePrevious, history } from '../../../../../helpers';

export default function BccSearchTerms() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [bccSearchTerms, setBccSearchTerms] = useState([]);
  const [filtersConfig, setFiltersConfig] = useState(defaultFilters);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [userDateRequest, setUserDateRequest] = useState(false);

  const filtersConfigPrev = usePrevious(filtersConfig);
  const itemName = selectedSeller.type === 1 ? 'SKU' : 'ASIN';
  const defaultStartDate = moment().subtract(34, 'days');
  const defaultEndDate = moment().subtract(4, 'days');

  const columnDefs = [
    {
      field: 'bcc',
      headerName: 'BCC Type',
    },
    {
      field: 'search_term',
      headerName: 'Search Term',
    },
    {
      field: 'impressions',
      headerName: 'Impressions',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'clicks',
      headerName: 'Clicks',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      valueFormatter: formatDecimalToFixedTwoPercent,
    },
    {
      field: 'spend',
      headerName: 'Spend',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'sales',
      headerName: 'Sales',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'acos',
      headerName: 'AcOS',
      valueFormatter: formatDecimalToFixedTwoPercent,
    },
    {
      field: 'roas',
      headerName: 'ROAS',
      valueFormatter: formatNumberOnePlaceDefaultToZero,
    },
    {
      field: 'orders',
      headerName: 'Orders',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'cvr',
      headerName: 'CVR',
      valueFormatter: formatDecimalToFixedTwoPercent,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  useEffect(() => {
    if ((moment(filtersConfig.dateRange.start).format('YYYYMMDD') === moment(defaultStartDate).format('YYYYMMDD') && moment(filtersConfig.dateRange.end).format('YYYYMMDD') === moment(defaultEndDate).format('YYYYMMDD')) || userDateRequest) {
      if (filtersConfig.metricView && filtersConfigPrev && filtersConfigPrev.metricView.value === filtersConfig.metricView.value) {
        setUserDateRequest(true);
        setLoadingData(true);
        const bccSearchTermsUrl = `${serverUrl}/amazon/bcc-search-terms?accountId=${selectedSeller.value}&parentAccountId=${selectedSeller.parentAccountId}&accountType=${selectedSeller.type}&filters=${encodeURIComponent(JSON.stringify(filtersConfig))}`;
        const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user.jwtToken}` };
        const requestOptions = {
          method: 'GET',
          headers: headerWithAuth,
        };

        fetch(bccSearchTermsUrl, requestOptions)
          .then((results) => {
            if (results.status === 401) {
              history.push('/logout');
            }
            if (!results.ok) {
              throw Error(results.statusText);
            }
            return results.json();
          }).then((data) => {
            setBccSearchTerms(data);
            setLoadingData(false);
          });
      }
    }
  }, [selectedSeller.value, filtersConfig]);

  const exportPrefix = getExportPrefix('amz-bcc-search-terms', selectedSeller, true);

  return (
    <Container className="dashboard">
      <Row>
        <ReportFilters
          setFiltersConfig={setFiltersConfig}
          itemName={itemName}
          loading={loadingFilters || loadingData}
          setLoading={setLoadingFilters}
          defaultDateRange={{ start: defaultStartDate, end: defaultEndDate }}
          filterCsv="dates,bcc,dataSet,portfolio,brand,subcategory,budgetGroup,showAds,items"
        />
      </Row>
      <Panel
        lg={12}
        md={12}
        title="Search Terms - TOP 100"
        subhead={filtersConfig.bccFilter}
        showRefreshButton={false}
      >
        <Row>
          <Col md={12}>
            {!loadingData && (
              <TableBuilder
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={bccSearchTerms}
                parentControlledLoad
                exportPrefix={exportPrefix}
                startDate={filtersConfig.dateRange.start.toString()}
                endDate={filtersConfig.dateRange.end.toString()}
              />
            )}
          </Col>
        </Row>
      </Panel>

    </Container>
  );
}

