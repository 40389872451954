import React from 'react';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../../../../../shared/prop-types/ReducerProps';
import LandingPageEditModalForm from './LandingPageEditModalForm';

const LandingPageEditModal = ({
  changeShowEditModal, showEditModal, theme, selectedData, setForceRefresh, budgetGroupOptions, setShowEditModal,
}) => {
  const modalClasses = classNames({
    'todo__add-modal': true,
  }, theme.className, 'ltr-support');

  return (
    <Modal
      isOpen={showEditModal}
      toggle={changeShowEditModal}
      className={modalClasses}
    >
      <LandingPageEditModalForm
        changeShowEditModal={changeShowEditModal}
        selectedData={selectedData}
        setForceRefresh={setForceRefresh}
        budgetGroupOptions={budgetGroupOptions}
        setShowEditModal={setShowEditModal}
      />
    </Modal>
  );
};

LandingPageEditModal.propTypes = {
  changeShowEditModal: PropTypes.func.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
};


export default LandingPageEditModal;
