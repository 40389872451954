import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import useData from './useData';
import { serverUrl, jsonHeader } from '../../../../../config';
import Panel from '../../../../../shared/components/Panel';
import Notify from '../../../../../shared/components/widgets/Notify';
import { history } from '../../../../../helpers';



export default function AccountStatusWithContext() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [forceRefresh, setForceRefresh] = useState(null);
  const [loading, results, error] = useData(selectedSeller, forceRefresh);
  const [createLoading, setCreateLoading] = useState(false);
  const [addAdvertiserLoading, setAddAdvertiserLoading] = useState(false);
  const [instanceName, setInstanceName] = useState(null);
  const [advertiserName, setAdvertiserName] = useState(null);
  const [advertisersToAdd, setAdvertisersToAdd] = useState([{ id: '', marketplaceId: '', type: '' }]);

  const createInstance = () => {
    if (selectedSeller && selectedSeller.value) {
      setCreateLoading(true);
      Notify.show('primary', 'Started Create Process', 'Instance create requested');
      const url = `${serverUrl}/accounts/amc/instance`;
      const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId: selectedSeller.value,
          instanceName,
          advertiserName,
        }),
      };

      fetch(url, requestOptions)
        .then((createResults) => {
          if (createResults.status === 401) {
            history.push('/logout');
          }
          if (!createResults.ok) {
            Notify.show('danger', 'Create API Error', createResults.statusText);
            throw Error(createResults.statusText);
          }
          return createResults.json();
        }).then((data) => {
          Notify.show('success', 'Create Reqeusted', JSON.stringify(data));
          setForceRefresh(moment().format());
        });

      setCreateLoading(false);
    }
  };

  const addAdvertisers = () => {
    if (selectedSeller && selectedSeller.value) {
      setAddAdvertiserLoading(true);
      Notify.show('primary', 'Started Add Advertisers Process', 'Requesting to add Advertisers to your AMC instance');
      const url = `${serverUrl}/accounts/amc/instance/advertisers/updates`;
      const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId: selectedSeller.value,
          advertisersToAdd,
        }),
      };

      fetch(url, requestOptions)
        .then((createResults) => {
          if (createResults.status === 401) {
            history.push('/logout');
          }
          if (!createResults.ok) {
            Notify.show('danger', 'Add Advertiders API Error', createResults.statusText);
            throw Error(createResults.statusText);
          }
          return createResults.json();
        }).then((data) => {
          Notify.show('success', 'Add Advertisers Requested', JSON.stringify(data));
          setForceRefresh(moment().format());
        });

      setAddAdvertiserLoading(false);
    }
  };

  const updateAdvertisersToAdd = (field, position, value) => {
    const newAdverstierToAdd = [...advertisersToAdd];
    newAdverstierToAdd[position][field] = value;
    setAdvertisersToAdd(newAdverstierToAdd);
  };

  const removeRowAdvertisersToAdd = (position) => {
    setAdvertisersToAdd(advertisersToAdd.toSpliced(position, 1));
  };

  const addRowAdvertisersToAdd = () => {
    const addRowAdverstierToAdd = [...advertisersToAdd, { id: '', marketplaceId: '', type: '' }];
    setAdvertisersToAdd(addRowAdverstierToAdd);
  };

  useEffect(() => {
    if (!loading) {
      let apiInstanceName = `ll${results?.account?.seller_account_name.replace(/[^a-zA-Z\d]/g, '')}`.toLowerCase();
      const apiAdvertiserName = results?.account?.seller_account_name.replace(/[^a-zA-Z\d\s]/g, '').replace('  ', ' ').replace('  ', ' ');
      if (!apiInstanceName.endsWith(selectedSeller.mpName.toLowerCase())) {
        apiInstanceName += selectedSeller.mpName.toLowerCase();
      }
      setInstanceName(apiInstanceName);
      setAdvertiserName(apiAdvertiserName);
      if (results?.instance?.entityId) {
        if (results?.updates?.advertiserUpdates?.advertiserUpdates.length === 0) {
          setAdvertisersToAdd([{ id: results?.instance?.entityId, marketplaceId: results?.account?.marketplace_code, type: 'SPONSORED_ADS' }, { id: results?.instance?.dspAccountId, marketplaceId: null, type: 'DISPLAY' }]);
        } else {
          setAdvertisersToAdd([{ id: '', marketplaceId: '', type: '' }]);
        }
      } else {
        setAdvertisersToAdd([{ id: '', marketplaceId: '', type: '' }]);
      }
    }
  }, [results]);

  return (
    <>
      {error}
      <Panel lg={12} title="Linked AMC Instance" parentRefresh={loading}>
        <h3 className="page-subhead subhead">For internal use only</h3>
        <Row>
          <Col md={6} lg={6} xl={6}>
            <p>
              Account ID: {selectedSeller.value}<br />
              Account Name: {results?.account?.seller_account_name}<br />
              AMC Instance ID: {results?.instance?.instanceId}<br />
              AMC Account ID: {results?.instance?.amcAccountId ? results?.instance?.amcAccountId : <b style={{ color: 'red' }}>*** PLEASE ADD &quot;AMC Manager Account ID&quot; TO ACCOUNT SETTINGS ***</b>}<br />
              Entity ID: {results?.instance?.entityId ? results?.instance?.entityId : <b style={{ color: 'red' }}>*** PLEASE ADD &quot;Entity ID&quot; TO ACCOUNT SETTINGS ***</b>}<br />
              DSP Account ID: {results?.instance?.dspAccountId ? results?.instance?.dspAccountId : <b style={{ color: 'red' }}>*** PLEASE ADD &quot;DSP Account ID&quot; TO ACCOUNT SETTINGS ***</b>}<br />
              <pre>{JSON.stringify(results?.instance?.instanceInfo, null, 2)}</pre>
              {/* <pre>{JSON.stringify(results?.account, null, 2)}</pre> */}
            </p>
          </Col>
          <Col md={6} lg={6} xl={6}>
            <h1>Step 1</h1>
            {results?.instance?.instanceInfo === null && (
              <p>
                On-board AMC Instance, takes about 1 hour after clicking to complete setup.
                <br />
                <br />
                <br />
                <h3>Instance Name:</h3><input type="text" id="instanceName" className="input_round" style={{ width: '100%' }} value={instanceName} onChange={(e) => setInstanceName(e.target.value?.replace(/[^a-zA-Z\d:]/g, '').toLowerCase())} />
                <br />
                <br />
                <h3>Advertiser Name:</h3><input type="text" id="advertiserName" className="input_round" style={{ width: '100%' }} value={advertiserName} onChange={(e) => setAdvertiserName(e.target.value?.replace(/[^a-zA-Z\d\s:]/g, '').replace('  ', ' '))} />
                <br />
                <br />
                <button type="button" style={{ width: '50%' }} className="btn btn-primary account__btn" onClick={() => createInstance()} disabled={createLoading || (results?.instance?.amcAccountId || '') === ''}>Create Instance</button>
              </p>
            )}
            {results?.instance?.instanceInfo !== null && (
              <p>
                <h3>{results?.instance?.instanceInfo?.instance.creationStatus || 'PROCESSING'}</h3>
              </p>
            )}
            <br />
            <br />
            <h1>Step 2</h1>
            {results?.instance?.instanceInfo !== null && results?.instance?.instanceInfo?.instance.creationStatus !== 'COMPLETED' && (
              <p>
                <h3>Waiting on Step 1 to Complete, refresh to check for changes.</h3>
              </p>
            )}
            {results?.instance?.instanceInfo !== null && results?.instance?.instanceInfo?.instance.creationStatus === 'COMPLETED' && (
              <p>
                <h3>Add Advertisers (Remove will be built on request)</h3>
                Adding Advertisers can take several days to complete.  Advertiser Types accepted: SPONSORED_ADS and DISPLAY (DSP), the associated ID must be of that type (Sponsored Ads is a Entity and DSP is a number).<br />
                Marketplace ID is only needed for SPONSORED_ADS.
                <br />
                <pre>{JSON.stringify(results?.updates, null, 2)}</pre>
                <br />
                <table>
                  <tr>
                    <th style={{ padding: '5px' }}>ID</th>
                    <th style={{ padding: '5px' }}>Marketplace ID</th>
                    <th style={{ padding: '5px' }}>Type</th>
                    <th style={{ padding: '5px' }}>Action</th>
                  </tr>
                  {advertisersToAdd && advertisersToAdd.map((addAdvertiser, index) => (
                    <tr>
                      <td style={{ padding: '5px' }}><input type="text" id={`addAdvertiserId${index}`} className="input_round" style={{ width: '100%' }} value={addAdvertiser.id || ''} onChange={(e) => updateAdvertisersToAdd('id', index, e.target.value.toUpperCase())} /></td>
                      <td style={{ padding: '5px' }}><input type="text" id={`addAdvertiserMarketplaceId${index}`} className="input_round" style={{ width: '100%' }} value={addAdvertiser.marketplaceId || ''} onChange={(e) => updateAdvertisersToAdd('marketplaceId', index, e.target.value.toUpperCase())} /></td>
                      <td style={{ padding: '5px' }}><input type="text" id={`addAdvertiserType${index}`} className="input_round" style={{ width: '100%' }} value={addAdvertiser.type || ''} onChange={(e) => updateAdvertisersToAdd('type', index, e.target.value.toUpperCase())} /></td>
                      <td style={{ padding: '5px', paddingTop: '20px' }}><button type="button" id={`remove${index}`} className="btn btn-primary account__btn" onClick={() => removeRowAdvertisersToAdd(index)}>Remove</button></td>
                    </tr>
                  ))}
                </table>
                <br />
                <br />
                <button type="button" style={{ width: '20%' }} id="addRow" className="btn btn-secondary account__btn" onClick={() => addRowAdvertisersToAdd()}>Add Row</button>
                <br />
                <button type="button" style={{ width: '50%' }} className="btn btn-primary account__btn" onClick={() => addAdvertisers()} disabled={addAdvertiserLoading || (results?.instance?.instanceInfo?.instance.instanceId || '') === ''}>Add Advertisers</button>
                <br />
              </p>
            )}
          </Col>
        </Row>
      </Panel>
    </>
  );
}
