import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import moment from 'moment';
import CompetitionTable from './CompetitionTable';
import CompetitionTreemap from './CompetitionTreemap';
import { serverUrl, jsonHeader } from '../../../../../config';
import { getExportPrefix } from '../../../../../shared/components/table/functions';
import ReportFilters, { defaultFilters } from '../../../../../shared/components/filters/ReportFilters';
import { usePrevious, history } from '../../../../../helpers';

export default function BccTopCompetitorsPage() {
  const selectedSeller = useSelector(state => state.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [bccTopCompetitors, setBccTopCompetitors] = useState([]);
  const [filtersConfig, setFiltersConfig] = useState(defaultFilters);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [userDateRequest, setUserDateRequest] = useState(false);

  const filtersConfigPrev = usePrevious(filtersConfig);
  const itemName = selectedSeller.type === 1 ? 'SKU' : 'ASIN';
  const defaultStartDate = moment().subtract(34, 'days');
  const defaultEndDate = moment().subtract(4, 'days');

  useEffect(() => {
    // If statment tso we don't load the default dates
    if ((moment(filtersConfig.dateRange.start).format('YYYYMMDD') === moment(defaultStartDate).format('YYYYMMDD') && moment(filtersConfig.dateRange.end).format('YYYYMMDD') === moment(defaultEndDate).format('YYYYMMDD')) || userDateRequest) {
      if (filtersConfig.metricView && filtersConfigPrev && filtersConfigPrev.metricView.value === filtersConfig.metricView.value) {
        setUserDateRequest(true);
        // setPartialDataNote(false);
        setLoadingData(true);
        const bccTopCompetitorsUrl = `${serverUrl}/amazon/bcc-top-competitors?accountId=${selectedSeller.value}&parentAccountId=${selectedSeller.parentAccountId}&accountType=${selectedSeller.type}&filters=${encodeURIComponent(JSON.stringify(filtersConfig))}`;
        const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user.jwtToken}` };
        const requestOptions = {
          method: 'GET',
          headers: headerWithAuth,
        };

        fetch(bccTopCompetitorsUrl, requestOptions)
          .then((results) => {
            if (results.status === 401) {
              history.push('/logout');
            }
            if (!results.ok) {
              throw Error(results.statusText);
            }
            return results.json();
          }).then((data) => {
            setBccTopCompetitors(data);
            setLoadingData(false);
          });
      }
    }
  }, [selectedSeller, filtersConfig]);

  const exportPrefix = getExportPrefix('bcc-top-competitors', selectedSeller, true);

  return (
    <Container className="dashboard">
      <ReportFilters
        setFiltersConfig={setFiltersConfig}
        itemName={itemName}
        loading={loadingFilters || loadingData}
        setLoading={setLoadingFilters}
        defaultDateRange={{ start: defaultStartDate, end: defaultEndDate }}
        filterCsv="dates,metric,bcc,dataSet,portfolio,brand,subcategory,budgetGroup,showAds,items"
      />
      <CompetitionTreemap
        title="Competitor Treemap"
        loading={loadingFilters || loadingData}
        data={bccTopCompetitors}
        metric={filtersConfig.metricView}
      />
      <CompetitionTable
        title="Competitor Breakdown"
        subtitle={`${filtersConfig.dataSourceOption.label} - ${filtersConfig.dateRange.start.format('MMMM DD, YYYY')} to ${filtersConfig.dateRange.end.format('MMMM DD, YYYY')}`}
        loading={loadingFilters || loadingData}
        data={bccTopCompetitors}
        exportPrefix={exportPrefix}
        startDate={filtersConfig.dateRange.start.toString()}
        endDate={filtersConfig.dateRange.end.toString()}
        metric={filtersConfig.metricView}
      />
    </Container>
  );
}
