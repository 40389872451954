import React from 'react';
import PropTypes from 'prop-types';
import FilterSelect from '../../../../shared/components/filters/FilterSelect';

export const metrics = {
  impressions: {
    label: 'Impressions',
    key: 'impressions',
    value: 'impressions_1d',
  },
  clicks: {
    label: 'Clicks',
    key: 'clicks',
    value: 'clicks_1d',
  },
  spend: {
    label: 'Spend',
    key: 'cost',
    value: 'cost_1d',
  },
  sales: {
    label: 'Sales',
    key: 'attributedSales14d',
    value: 'attributedSales14d_1d',
  },
  orders: {
    label: 'Orders',
    key: 'attributedConversions14d',
    value: 'attributedConversions14d_1d',
  },
  units: {
    label: 'Units',
    key: 'attributedUnitsOrdered14d',
    value: 'attributedUnitsOrdered14d_1d',
  },
  * [Symbol.iterator]() {
    yield metrics.impressions;
    yield metrics.clicks;
    yield metrics.spend;
    yield metrics.sales;
    yield metrics.orders;
    yield metrics.units;
  },
};

export default function MetricSelector({
  setValue, disable, value,
}) {
  return (
    <div style={{ width: '25%', zIndex: '100000' }}>
      <FilterSelect
        label="Metric:"
        onChange={option => setValue(option)}
        name="dataOption"
        options={[...metrics]}
        selected={value}
        disable={disable}
      />
    </div>
  );
}

MetricSelector.defaultProps = {
  disable: false,
  value: metrics.impressions,
};

MetricSelector.propTypes = {
  setValue: PropTypes.func.isRequired,
  disable: PropTypes.bool,
  value: PropTypes.oneOf([...metrics]),
};
