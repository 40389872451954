import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Table,
} from 'reactstrap';
import CardPanel from '../../../../shared/components/widgets/CardPanel';

export default function ProductPerformance({
  loading, title, subtitle, data, field,
}) {
  return (
    <CardPanel
      title={title}
      subhead={subtitle}
      showButtons={false}
      parentRefresh={loading}
    >
      {!loading && data && (
        <Table responsive striped className="dashboard__table-orders" id="executiveSummary">
          <thead>
            <tr>
              <th>PRODUCTS</th>
              <th>{field.toUpperCase()}</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item.asin}>
                <td>{item.displayTitle}<br /><span><Badge href={item.productLink} target="_blank" color="primary">{item.asin}</Badge></span></td>
                <td>{item.displayValue}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!data && !loading && (
        <p>Not available...</p>
      )}
    </CardPanel>
  );
}

ProductPerformance.defaultProps = {
  subtitle: 'By ACoS for Date Range',
  data: [],
  field: 'acos',
};

ProductPerformance.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  field: PropTypes.string,
};
