import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LoggedInRoutes = ({
  component: Component, ...rest
}) => {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const agencyData = JSON.parse(selectedSeller.agency || '{}');
  const agency = agencyData.code || 'limitless';

  return (
    <Route
      {...rest}
      render={props => (
        (loggedIn && (user.access === 'admin' || agency !== 'adrev'))
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )}
    />
  );
};

export default LoggedInRoutes;
