import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'reactstrap';
import Panel from '../../../../../shared/components/Panel';
import { columnDefs, defaultColumnDefs } from './columns';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';

export default function CompetitionTable({
  title, subtitle, loading, data, exportPrefix, startDate, endDate, metric,
}) {
  const [agGrid, setAgGrid] = useState(null);

  const parentAccess = (agGridPointer) => {
    setAgGrid(agGridPointer);
  }

  useEffect(() => {
    if (!loading && agGrid && agGrid.gridColumnApi) {
      agGrid.gridColumnApi.applyColumnState({
        state: [{ colId: metric.value, sort: 'desc' }],
        defaultState: { sort: null },
      });
    }
  }, [metric]);

  return (
    <Panel
      md={12}
      title={title}
      subhead={subtitle}
      parentRefresh={loading}
      showRefreshButton={false}
    >
      <Row>
        <Col>
          {!loading && ( // This is needed to recreate the object with new data
            <TableBuilder
              columnDefs={columnDefs}
              defaultColDef={defaultColumnDefs}
              rowData={data}
              showFooter
              parentControlledLoad
              exportPrefix={exportPrefix}
              startDate={startDate}
              endDate={endDate}
              parentAccess={parentAccess}
            />
          )}
        </Col>
      </Row>
    </Panel>
  );
}

CompetitionTable.defaultProps = {
  loading: false,
  data: null,
};

CompetitionTable.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
};
