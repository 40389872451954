import { useState, useEffect } from 'react';
import {
  withArray,
} from '../../../../../shared/functions';
import useAccountOverTime from '../../../../../shared/components/hooks/useAccountOverTime';
import useVendorSalesOrdersOverTime from '../../../../../shared/components/hooks/useVendorSalesOrdersOverTime';
import useAsinCounts from '../../../../../shared/components/hooks/useAsinCounts';
import { DataSets } from '../../../../../shared/components/filters/DataSetFilter';

export default function useData(account, start, end, view) {
  const dataSet = DataSets.ALL;
  const [accountLoading, accountResults, accountError] = useAccountOverTime(account, start, end, view, dataSet);
  const [ordersLoading, ordersResults, ordersError] = useVendorSalesOrdersOverTime(account, start, end, view);
  const [asinsLoading, asinsResults, asinsError] = useAsinCounts(account);
  const loading = accountLoading || ordersLoading || asinsLoading;
  const [error, setError] = useState('');
  const [results, setResults] = useState(null);

  useEffect(() => {
    const data = withArray(accountResults, () => {
      const ordersMap = withArray(ordersResults, () => {
        const map = new Map();
        ordersResults.forEach(item => map.set(item.report_date, item));
        return map;
      }, () => new Map());

      const output = [];
      accountResults.forEach((result) => {
        const temp = result;
        const orders = ordersMap.get(result.rankDate);
        if (orders) {
          temp.ordered_revenue = orders.ordered_revenue;
          temp.organic_revenue = result.ordered_revenue - result.sales;
          temp.ppc_sales_percent = result.sales / result.ordered_revenue;
          temp.total_acos = result.spend / result.ordered_revenue;
          temp.glance_views = orders.glance_views;
          temp.ppc_view_percent = result.clicks / result.glance_views;
        }
        const asinCounts = asinsResults[0];
        if (asinCounts) {
          temp.ppc_asins = asinCounts.ppc_asins || 0;
          temp.organic_asins = asinCounts.organic_asins || 0;
          temp.total_asins = asinCounts.total_asins || 0;
          temp.ppc_sales_asin = (temp.ppc_asins > 0) ? temp.sales / temp.ppc_asins : 0;
          temp.organic_sales_asin = (temp.organic_asins > 0) ? temp.organic_revenue / temp.organic_asins : 0;
          temp.total_sales_asin = (temp.total_asins > 0) ? temp.ordered_revenue / temp.total_asins : 0;
        }
        output.push(temp);
      });
      return output;
    }, () => []);

    setResults({
      ...results,
      data,
    });

    return (() => {
      setResults({
        ...results,
        data: null,
      });
    });
  }, [accountResults, ordersResults, asinsResults, setResults]);

  useEffect(() => {
    if (accountError) {
      setError(accountError);
    }
  }, [accountError]);

  useEffect(() => {
    if (ordersError) {
      setError(ordersError);
    }
  }, [ordersError]);

  useEffect(() => {
    if (asinsError) {
      setError(asinsError);
    }
  }, [asinsError]);

  return [loading, results, error];
}
